import { Component, OnInit, Inject } from '@angular/core';
import { API } from '../../provider/api';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { delay } from 'q';
import { InfoModalsComponent } from '../info-modals/info-modals.component';


@Component({
  selector: 'app-delete-filter',
  templateUrl: './delete-filter.component.html',
  styleUrls: ['./delete-filter.component.css'],
})
export class DeleteFilterComponent implements OnInit {

  filters: any;


  constructor(public diag:MatDialog, public dialogRefPlan: MatDialogRef<DeleteFilterComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public api: API ) {
    this.filters = this.data.filter;

  }

  ngOnInit() {
  }

 

  delete() {
        this.api.deleteFilter(this.filters,this.filters['id']).subscribe(response =>{
          // console.log(response);
          // if(response["STATUS"] == 0){
          
          const diagDelete = this.diag.open(InfoModalsComponent, {
            width: "50vw",
            data: {
              title: "Delete Filter",
              content: "Filter deleted successfully"
            }
          });

          diagDelete.afterClosed().subscribe(result => {     
          this.diag.closeAll();
          });

        // }
        
      });
      (err) => {
        console.log(err);
        this.dialogRefPlan.close();
      }
    
  }
  cancelPlanDialog() {
  //  this.dialogRefPlan.close();
     this.diag.closeAll();
  }

}
