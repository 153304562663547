import { Component, OnInit, ViewChild } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { StockChart } from 'angular-highcharts';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material';
import * as Highcharts from 'highcharts/highstock';
import { Inject } from '@angular/core';
import { API } from '../../provider/api';
import { InfoModalsComponent } from '../info-modals/info-modals.component';
import {PageEvent} from '@angular/material/paginator';
import { MatPaginator } from '@angular/material';

export interface ModalInfo {
  modalTitle: string;
  modalContent: string;
}

export interface Flag {
  x: number;
  title: string;
  text: string;
}




@Component({
  selector: 'app-detail-graph',
  templateUrl: './detail-graph.component.html',
  styleUrls: ['./detail-graph.component.scss']
})



export class DetailGraphComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  stock: StockChart;
  rumours_spin = true;
  graph_spin = true;
  ticker: any;
  planType: string;
  rumoursDetails: any;
  companyName: string;
  companySectorDetails: string;
  marketCap: string;
  intradayPrice: string;

  _52wkHigh: string;
  _52wkLow: string;
  grossMargin: string;
  netMargin: string;
  fromFlag: number;
  source: string;
  sourcePopularity: number=0;
  sourceReliability: number=0;
  last30Days:number=0;
  totalRumors: number=0;
  arraylength: number = 0;
 // MatPaginator Inputs
 length = 100;
 pageSize = 25;
 pageFetchSize = 25;
 pageNumber = 1;

 invertX = false;
 invertY = false;
 shown = "native";
 compact = true;
 disabled = false;

 pageSizeOptions: number[] = [5, 10, 25, 100];
 // MatPaginator Output
 pageEvent: PageEvent;
  closePoints = [];
  highPoints = [];
  lowPoints = [];
  candleStick = [];
  flags: Flag[]=[];
  isFeedLoaded : boolean = false;
  validity: any;
  validFrom: any;
  validTo: any;
  expireDate: number;
  constructor(public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, public api: API) {
    this.ticker = data.ticker;
    this.fromFlag = data.fromFlag;
    this.rumoursDetails = [];
    this.planType=JSON.parse(localStorage.getItem('userSubscription'))['subscriptionType'];
  }

  onNoClick() {
    this.dialog.closeAll();
  }

  ngOnInit() {
    this.getRumoursDetails();
    if(this.fromFlag == 1){
      this.getFeeds();
    }

    this.validity = JSON.parse(localStorage.getItem('userSubscription'))['validity'];
    this.validFrom  =new Date (JSON.parse(localStorage.getItem('userSubscription'))['validFrom']);
    this.validTo = new Date (JSON.parse(localStorage.getItem('userSubscription'))['validTo']);
    // console.log((this.validTo-this.validFrom)/(60*1000*60*24),(new Date).getTime());
    if ((this.validTo-this.validFrom)/(60*1000*60*24) == 14 ){
        this.expireDate = Math.ceil((this.validTo - (new Date).getTime())/(60*1000*60*24)) ;
        // console.log("exp date --"+ Math.ceil(this.expireDate));
    }
    this.planType = JSON.parse(localStorage.getItem('userSubscription'))['subscriptionType'];

  }

  modalInfo: ModalInfo[] = [
    {modalTitle : 'Source URL and Reference', modalContent: '<p>Hover over the light blue i-button icons to view the text of each rumor.</p>'},
    {modalTitle : 'Source Popularity', modalContent: '<p>The popularity of a rumor is the credibility score of an individual source and is based on traffic, visitor demographics, social media influence, and bias-free expert curation.</p>'},
    {modalTitle : 'Source Reliability Index', modalContent: '<p>Reliability is a value that scales from 0-5 stars based on a source\'s ability to publish rumors that propagate to higher popularity sources in terms of both speed and breadth. High-reliability sources that may not be highly popular tend to exhibit high alpha content. A score of 5 stars indicates the highest reliability and a score of 0 stars indicates that a source has yet to build a track record.</p>'},
    {modalTitle : 'Est. Price Impact', modalContent: '<h3>What is Estimated Price Impact? </h3><p>Estimated Price Impact shows the highest confidence expected move of the target company\'s stock price relative to the price when the rumor was detected. This prediction is valid until a new rumor for the target company is detected, or 10 days after initial rumor detection -- whichever comes first.</p> <h3>How do I read the Prediction Progress Bars? </h3> <p>When the prediction is valid, the green area (red if the prediction is negative) represents the maximum price move that has been achieved given the predicted range since the rumor was detected.</p><p><img src="./assets/images/indicator.png" width="320px"></p><p>The Prediction Progress Bars will deactivate when the prediction expires. A rumor expires after 10 days from initial rumor detection, or immediately when a new rumor comes in during the 10-day period. </p><p><img src="./assets/images/indicator-disabled.png" width="320px"></p>  ' },
  ]

  infoModals(i) {
    this.dialog.open( InfoModalsComponent,{
      width: "50vw",
      data: {
        title: this.modalInfo[i].modalTitle,
        content: this.modalInfo[i].modalContent

      }
    });
  }


  getSourceURL(stringData){
    if(stringData){
      if(stringData.indexOf("http") == -1){
        return "//"+stringData;
      }
      return stringData;
    }
  }
  getRumoursDetails() {
    this.rumoursDetails = [];
    if(this.fromFlag == 1){
    this.api.getRumoursDetails(this.ticker, this.pageFetchSize, this.pageNumber).subscribe(response => {

      this.rumoursDetails = response['data'];
      if (this.rumoursDetails.length > 0 && this.pageNumber == 1) {
        this.companyName = this.rumoursDetails[0].acquiree.companyName;
        this.companySectorDetails = this.rumoursDetails[0].acquiree.sector + " - " + this.rumoursDetails[0].acquiree.industry;
        this.marketCap = (this.rumoursDetails[0].acquiree.marketCap).toString();
        this.intradayPrice = this.rumoursDetails[0].lastTradedPrice;
        this._52wkHigh = this.rumoursDetails[0].acquiree.weekHigh52;
        this._52wkLow = this.rumoursDetails[0].acquiree.weekLow52;
        this.grossMargin = this.rumoursDetails[0].acquiree.grossMargins;
        this.netMargin = this.rumoursDetails[0].acquiree.netMargins;
      }
      // this.rumoursDetails.forEach(element => {
      // if(this.flags.find(v => v.x == this.getEpoch(element.startTimestampEpoch) && v.title == element.acquirer.ticker && v.text == element.bubbleText) == undefined){
      //   this.flags.push({
      //     x: element.startTimestampEpoch,
      //     title: element.acquirer.ticker,
      //     text: element.bubbleText
      //   });
      // }
      // });
     // console.log(this.flags);


      // this.length=response['total'];
      // this.arraylength = response['data'].length;
      // if(this.isFeedLoaded){
      //     this.plotGraph();
      // }

      this.length=response['total'];
      this.arraylength = response['data'].length;
      if(this.isFeedLoaded){
          this.plotGraph();
      }
      var i = 0;
      this.rumoursDetails.forEach(element => {
        let tickerObj = element.acquirer.ticker == "UNK" ? "UNKNOWN" : element.acquirer.ticker ;
      if(this.flags.find(v => v.x == (element.startTimestampEpoch-14400000) 
      && v.title == ((this.length-(this.pageFetchSize*(this.pageNumber-1))) - i).toString()
      && v.text ==  tickerObj) == undefined){
        this.flags.push({
          x: (element.startTimestampEpoch-14400000),
          title: ((this.length-(this.pageFetchSize*(this.pageNumber-1))) - i).toString(),
         //  title: element.acquirer.ticker,
          text: tickerObj
        });
      }
      i++;
      });
      this.rumours_spin = false;
    }, (err) => {
      console.log(err);
      this.rumours_spin = false;
    });
  }else if(this.fromFlag == 2){
    this.api.getRumoursDetailsForSource(this.ticker,this.pageFetchSize,this.pageNumber).subscribe(response => {

      this.rumoursDetails = response['data'];
      if (this.rumoursDetails.length > 0) {
        this.source = this.rumoursDetails[0].source;
        this.sourcePopularity = this.rumoursDetails[0].popularity;
        this.sourceReliability = this.rumoursDetails[0].reliabilityStars;
        this.last30Days = response['total'];
        this.totalRumors = response['total'];

      }
      this.length=response['total'];
      this.arraylength = response['data'].length;
      this.rumours_spin = false;
    }, (err) => {
      console.log(err);
      this.rumours_spin = false;
    });
  }else{
    this.api.getDealsData(this.pageFetchSize,this.pageNumber).subscribe(response => {
      this.arraylength = response['data'].length;
      this.rumoursDetails = response['data'];
      this.length=response['total'];

      this.rumours_spin = false;


    }, (err) => {
      console.log(err);
      this.rumours_spin = false;
    });
  }

  }
  getFeeds(){
    this.api.getFeeds(this.ticker).subscribe(response => {
      let feedDetails = response['data'];

      feedDetails.forEach(element => {
       // console.log(this.getEpoch(element.barDate));
        this.closePoints.push([element.barDateEpoch - 14400000, element.close]);
        this.highPoints.push([element.barDateEpoch - 14400000, element.high]);
        this.lowPoints.push([element.barDateEpoch - 14400000, element.low]);
        this.candleStick.push([element.barDateEpoch - 14400000,element.open,element.high,element.low,element.close]);
      });
      this.closePoints.reverse();
      this.highPoints.reverse();
      this.lowPoints.reverse();
      this.candleStick.reverse();
      this.isFeedLoaded = true;
      this.plotGraph();
    this.graph_spin = false;
    }, (err)=>{
      console.log(err);
    });
  }

  plotGraph(){
    //console.log(this.dataPoints[0]);
   // let dataPointObj = this.dataPoints;
   // console.log
     // console.log(dataPointObj[0]);
    //  console.log(this.dataPoints[0]);
    this.stock = new StockChart({
      colors: ['#2f7ed8','#2f7ed8','#45B124', '#FF5A52', '#2f7ed8', '#910000', '#1aadce',
      '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a'],
      exporting: {
        buttons: {
    
          contextButton: {
            menuItems: ['Candlestick', 'Line']
          }
        },
        menuItemDefinitions: {
          // Custom definition
          Candlestick: {
            onclick: function () {
              this.series.forEach(element => {
                element.setVisible(false);
              });
              this.series[0].setVisible(true);
              this.series[4].setVisible(true);
    
            },
            text: 'Candlestick'
          },
          Line: {
            onclick: function () {
              this.series.forEach(element => {
                element.setVisible(false);
              });
              this.series[1].setVisible(true);
              // this.series[2].setVisible(true);
              // this.series[3].setVisible(true);
              this.series[5].setVisible(true);
            },
            text: 'Line'
          },
    
        },
        enabled: true
      },
      rangeSelector: {
        selected: 1
      },
      title: {
        text: this.ticker + ' Stock Price'
      },
       credits: {
        enabled: false
      },
      series: [{
        tooltip: {
          valueDecimals: 2
        },
        name: this.ticker,
        data: this.candleStick,
        type: 'candlestick',
        id: 'candlestickSeries'
      },{
        tooltip: {
          valueDecimals: 2
        },
        name: this.ticker+' Close',
        data: this.closePoints,
        type: undefined,
        visible:false,
        id: 'closeSeries'
    },{
      tooltip: {
        valueDecimals: 2
      },
      visible:false,
      name: this.ticker+' High',
      data: this.highPoints,
      type: undefined,
      id: 'highSeries'
  },{
    tooltip: {
      valueDecimals: 2
    },
    visible:false,
    name: this.ticker+' Low',
    data: this.lowPoints,
    type: undefined,
    id: 'lowSeries'
},{
  type: 'flags',
  data: this.flags,
  shape: 'circlepin',
  width: 16,
  onSeries: 'candlestickSeries'
  
}, {
  type: 'flags',
  data: this.flags,
  shape: 'circlepin',
  visible: false,
  width: 16,
  onSeries: 'closeSeries'
}]
  });
  // console.log(this.stock);
  }

  // getEpoch(datestring){
  //   //var parts = datestring.match(/(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2})/);
  //   return new Date(datestring).getTime();
  // }

  getDateFormated(dateText) {

    var date = new Date(dateText  - 14400000);
    return (date.getUTCFullYear().toString().length == 1 ? "0" + (date.getUTCFullYear()).toString() : date.getUTCFullYear().toString()) + "-" +
    ((date.getUTCMonth() +1).toString().length == 1 ? "0" + (date.getUTCMonth() + 1).toString() : (date.getUTCMonth() + 1).toString()) + "-" +
      (date.getUTCDate().toString().length == 1 ? "0" + date.getUTCDate().toString() : date.getUTCDate().toString()) + " " +
      (date.getUTCHours().toString().length == 1 ? "0" + date.getUTCHours().toString() : date.getUTCHours().toString()) + ":" +
      (date.getUTCMinutes().toString().length == 1 ? "0" + date.getUTCMinutes().toString() : date.getUTCMinutes().toString());
  }


  getRumourStatus(dateText) {
    var rDate = new Date(dateText);
    var ndate = new Date();
    //console.log(dateText);
    return (Math.ceil(Math.abs(ndate.getTime() - rDate.getTime()) / (1000 * 60 * 60 * 24)) > 10);

  }
  roundValue(value){
    // console.log(value);
     return Math.round(value/10)/100;
   }

   getServerData(event?: PageEvent){
   // console.log(event);
    this.pageFetchSize = event.pageSize;
    this.pageNumber = event.pageIndex + 1;
    this.getRumoursDetails();
    return event;
  }


  tooltipData(data){

    // console.log(this.planType);
    if (this.planType == 'PRO' || this.expireDate <= 14 ) {
       //console.log(data.estimatedPriceImpact1 , data.estimatedPriceImpact2)
       if (data.estimatedPriceImpact1 == 0 && data.estimatedPriceImpact2 == 0) {
        //console.log("if is riunning");
        return " NA. ";
      }
      else {
        // cosnsole.log("if is not  riunning");
        if (data.hitPercentage == -100) {
          return "Prediction Expired \nPrice When Rumor Detected: $" + data.priceWhenRumorDetected + "\nEstimated Price Impact: " + data.estimatedPriceImpact1 + "% - " + data.estimatedPriceImpact2 + " %\nMax. Move Since Rumor:N/A";
        } else {
          if (this.getRumourStatus(data.startTimestamp)) {
             if ((data.estimatedPriceImpact1 < 0 && data.estimatedPriceImpact2 > 0) || (data.estimatedPriceImpact1 >= 0 && data.hitPercentage > data.estimatedPriceImpact1) || (data.estimatedPriceImpact1 < 0 && data.hitPercentage < data.estimatedPriceImpact2))
              return "Prediction Achieved \nPrice When Rumor Detected: $" + data.priceWhenRumorDetected + "\nEstimated Price Impact: " + data.estimatedPriceImpact1 + "% - " + data.estimatedPriceImpact2 + " %\nMax. Move Since Rumor:  $" + data.maxPositiveMove + "  (" + data.hitPercentage + "%)";
            else
              return "Prediction Expired \nPrice When Rumor Detected: $" + data.priceWhenRumorDetected + "\nEstimated Price Impact: " + data.estimatedPriceImpact1 + "% - " + data.estimatedPriceImpact2 + " %\nMax. Move Since Rumor: $" + data.maxPositiveMove + "  (" + data.hitPercentage + "%)"; 

          } else {}
            return "Current Price: $" + data.lastTradedPrice + "\nPrice When Rumor Detected: $" + data.priceWhenRumorDetected + "\nEstimated Price Impact: " + data.estimatedPriceImpact1 + "% - " + data.estimatedPriceImpact2 + " %\nMax. Move Since Rumor: $" + data.maxPositiveMove + "  (" + data.hitPercentage + "%)";
        }
      }

    }else{
      return "Upgrade To Pro!"
    }
  }


getBarString(data) {
  if (data.hitPercentage == -100) {
    return data.estimatedPriceImpact1 + "% - " + data.estimatedPriceImpact2 + "%";
  } else {
    if (this.getRumourStatus(data.startTimestamp)) {
      if ((data.estimatedPriceImpact1 < 0 && data.estimatedPriceImpact2 > 0) || (data.estimatedPriceImpact1 >= 0 && data.hitPercentage > data.estimatedPriceImpact1) || (data.estimatedPriceImpact1 < 0 && data.hitPercentage < data.estimatedPriceImpact2))
        return "Achieved " + Math.round(data.hitPercentage).toFixed() + "%";
      else {
        return data.estimatedPriceImpact1 + "% - " + data.estimatedPriceImpact2 + "%";
      }
    } else if (data.estimatedPriceImpact1 == 0 && data.estimatedPriceImpact2 == 0) {
      return "Computing";
    } else {
      return data.estimatedPriceImpact1 + "% - " + data.estimatedPriceImpact2 + "%";
    }
  }
}

getBarStringgraph(data,index) {
  if (data.hitPercentage == -100) {
    return data.estimatedPriceImpact1 + "% - " + data.estimatedPriceImpact2 + "%";
  } else {
   // if (this.getRumourStatus(data.startTimestamp)) {
     if((this.pageNumber > 1 ) || (this.pageNumber == 1 && index > 0)||(this.getRumourStatus(data.startTimestamp))){
      if ((data.estimatedPriceImpact1 < 0 && data.estimatedPriceImpact2 > 0) || (data.estimatedPriceImpact1 >= 0 && data.hitPercentage > data.estimatedPriceImpact1) || (data.estimatedPriceImpact1 < 0 && data.hitPercentage < data.estimatedPriceImpact2))
        return "Achieved " + Math.round(data.hitPercentage).toFixed() + "%";
      else {
        return data.estimatedPriceImpact1 + "% - " + data.estimatedPriceImpact2 + "%";
      }
    } else if (data.estimatedPriceImpact1 == 0 && data.estimatedPriceImpact2 == 0) {
      return "Computing";
    } else {
      return data.estimatedPriceImpact1 + "% - " + data.estimatedPriceImpact2 + "%";
    }
  }
}


tooltipDatagraph(data,index){
  //console.log(data.estimatedPriceImpact1 , data.estimatedPriceImpact2)
  if (data.estimatedPriceImpact1 == 0 && data.estimatedPriceImpact2 == 0 ){
  //console.log("if is riunning");
  return "NA";
  }
  else {
    // cosnsole.log("if is not  riunning");
    if (data.hitPercentage == -100) {
      return "Prediction Expired \nPrice When Rumor Detected: $" + data.priceWhenRumorDetected + "\nEstimated Price Impact: " + data.estimatedPriceImpact1 + "% - " + data.estimatedPriceImpact2 + " %\nMax. Move Since Rumor:N/A";
    } else {
     // if (this.getRumourStatus(data.startTimestamp)) {
      // console.log(this.pageNumber , index)
      if((this.pageNumber > 1 ) || (this.pageNumber == 1 && index > 0)||(this.getRumourStatus(data.startTimestamp))){
        if ((data.estimatedPriceImpact1 < 0 && data.estimatedPriceImpact2 > 0) || (data.estimatedPriceImpact1 >= 0 && data.hitPercentage > data.estimatedPriceImpact1) || (data.estimatedPriceImpact1 < 0 && data.hitPercentage < data.estimatedPriceImpact2))
          return "Prediction Achieved \nPrice When Rumor Detected: $" + data.priceWhenRumorDetected + "\nEstimated Price Impact: " + data.estimatedPriceImpact1 + "% - " + data.estimatedPriceImpact2 + " %\nMax. Move Since Rumor:  $" + data.maxPositiveMove + "  (" + data.hitPercentage + "%)";
        else
          return "Prediction Expired \nPrice When Rumor Detected: $" + data.priceWhenRumorDetected + "\nEstimated Price Impact: " + data.estimatedPriceImpact1 + "% - " + data.estimatedPriceImpact2 + " %\nMax. Move Since Rumor: $" + data.maxPositiveMove + "  (" + data.hitPercentage + "%)";

      } else
        return "Current Price: $" + data.lastTradedPrice + "\nPrice When Rumor Detected: $" + data.priceWhenRumorDetected + "\nEstimated Price Impact: " + data.estimatedPriceImpact1 + "% - " + data.estimatedPriceImpact2 + " %\nMax. Move Since Rumor: $" + data.maxPositiveMove + "  (" + data.hitPercentage + "%)";
    }
  }
}


checkstandard(){
  if(this.planType == 'STANDARD'){
    if(this.expireDate <= 14){
        return true;
    }
    return false;
  }
  else{
    return true;
  }
}

checkplan(){

  if(this.planType == 'PRO' || this.expireDate <= 14){
    return true;
  }
  else{
    return false;
  }

}
exitModel(){
  this.dialog.closeAll();
}

}
