import { Component, OnInit } from '@angular/core';
import { API } from '../../provider/api'
import { CookieService } from 'ngx-cookie-service';
import { AppConstants } from '../../provider/appconstants';
import { MatButtonToggleChange } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-signup-new-plan',
  templateUrl: './signup-new-plan.component.html',
  styleUrls: ['./signup-new-plan.component.css']
})
export class SignupNewPlanComponent implements OnInit {

  email : String="";
  plan : String = "";
  clientBaseUrl: String;
  toggle: boolean = true;
  signUpNewPlan: FormGroup;
  constructor( public api : API,
                private cookie: CookieService,
                public appConstants: AppConstants,
                signUp: FormBuilder,
                fb: FormBuilder,
                public router: Router,
                private activatedRoute: ActivatedRoute
                ) {
                  this.signUpNewPlan = signUp.group({ });
                 }

  ngOnInit() {
   if (this.activatedRoute.snapshot.queryParams.ref != undefined) {
     localStorage.setItem("referralCode",this.activatedRoute.snapshot.queryParams.ref);
   }
  this.clientBaseUrl = this.appConstants.baseURL;
  this.getEmail();
  }
  selectPlan(planname){
    // console.log(planname);

    this.router.navigate(['/signup'],{ queryParams: { planname: planname } });

  }

  getEmail(){
    //this.email=localStorage.getItem["CustomerId"];
    this.email= this.cookie.get('acEmail');
    // console.log(this.email);
  }

  // selectPlan(plan){
  //   console.log(plan);
  //   //this.api.newSubs(this.email,plan)
  //   this.api.newSubs(this.email,plan).subscribe();

  // }
  
  // onFormSubmit(e){

  //   console.log(e)
  //   // this.plan = this.signUpNewPlan.get('planname').value;
    
  //   // console.log(this.plan = this.signUpNewPlan.get('planname').value)
  // }


  toggleView(change: MatButtonToggleChange){
    this.toggle = change.value;
  }

}
