import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { API } from '../../provider/api';
import { CookieService } from 'ngx-cookie-service';
import { TermsAndConditionsComponent } from '../terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from '../privacy-policy/privacy-policy.component';
import { MatDialog } from '@angular/material';
import { delay } from 'q';
import { ExpBoxComponent } from '../exp-box/exp-box.component'

import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})

export class SigninComponent implements OnInit {
  options: FormGroup;
  signInForm: FormGroup;
  showError: string;
  currentURL: any ;
  customerId: string = "";
  planCode: string = "" ;
  customerName: string = "" ;
  // paymentStatus : string;
  // user : string ;

  constructor(fb: FormBuilder, signIn: FormBuilder,
    public router: Router,
    public api: API,
    private cookie: CookieService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    ) {
      
      this.isAlreadySignIn();
      this.signInWithUrl();
      
      this.options = fb.group({
      hideRequired: false,
      floatLabel: 'auto',
    });

    this.signInForm = signIn.group({
      'email': [null, Validators.compose([Validators.required, Validators.email])],
      'password': [null, Validators.compose([Validators.required, Validators.minLength(6)])],
    });
  }
  ngOnInit() {
    
  }

  isAlreadySignIn(){
    const IsCookieExists: boolean = this.cookie.check('name');
    if (IsCookieExists) {
      this.router.navigate(['home']);
      console.log(this.cookie.get('name'))
    }
  }


  signInWithUrl(){
    // console.log(this.currentURL = window.location.href );
    this.currentURL = window.location.href; 
    //this.currentURL.getUserData();
    this.customerId = this.route.snapshot.queryParamMap.get("customerId");
    this.planCode =  this.route.snapshot.queryParamMap.get("planCode");
    this.customerName =  this.route.snapshot.queryParamMap.get("customerName");
    // console.log(this.customerId,this.planCode,this.customerName);
    
    if(this.customerId == null  && this.planCode == null && this.customerName == null){
        return;
    }
    else
     if (this.customerId != "" && this.planCode != "" && this.customerName != ""){
      this.cookie.set('acEmail', this.customerId);
      this.cookie.set('plan', this.planCode );
      this.cookie.set('name', this.customerName);
      this.cookie.set('email', this.customerId);
      this.getUserData();
    }
    
}

  onFormSubmit(valid) {

    if (valid) {
      let credentials = {
        userName: this.signInForm.get('email').value,
        password: this.signInForm.get('password').value
      };

      //login api
      this.api.login(credentials)
        .subscribe(response => {
          if (response["status"]) {
            //  console.log(response["data"]);



            this.cookie.set('acEmail', response["data"][0]["customerId"]);
            this.cookie.set('plan', response["data"][0]["planCode"]);
            this.cookie.set('name', response["data"][0]["customerName"]);
            this.cookie.set('email', response["data"][0]["customerId"]);



            // let custId = this.cookie.get('acEmail');
            // console.log(custId);



            this.getUserData();

            

          } else {

            if (response["data"][0] == "expired") {
              this.cookie.set('acEmail', credentials.userName);

                //open dialog
                const dialogRef = this.dialog.open(ExpBoxComponent, {
                });

                dialogRef.afterClosed();
                //end of dialog
              return;
            }
            // console.log(response["data"]);
          }
        }, (err) => {
          console.log(err);
          this.showError = err['error']['data'][0];
        });

    }
  }

  getUserData(){
    
    let custId = this.cookie.get('acEmail');
    // console.log(custId);
    this.api.getUserData(custId).subscribe(response1 => {
      if (response1["status"]) {
        localStorage.setItem("users", JSON.stringify(response1["data"][0]["customerInfoModel"]));
        localStorage.setItem("userSubscription", JSON.stringify(response1["data"][0]["userSubscriptionModel"]));
        localStorage.setItem("billingHistories", JSON.stringify(response1["data"][0]["billingHistories"]));
        localStorage.setItem("customerCardName", response1["data"][0]["customerCardName"]);
        localStorage.setItem("customerCardNumber", response1["data"][0]["customerCardNumber"]);
        localStorage.setItem("nextPaymentDate", response1["data"][0]["nextPaymentDate"]);
        localStorage.setItem("customerSubscriptionLicense", JSON.stringify(response1["data"][0]["customerSubscriptionLicense"]));
        // this.assignToInput();
        delay(5000);
        // delay(50000);
        this.router.navigate(['home']);
      } else {
        this.showError = response1['data'][0];
      }
    }, (err) => {
      console.log(err);
      this.showError = err['error']['data'][0];
    });

  }

  termsAndConditopns() {
    const diag = this.dialog.open(TermsAndConditionsComponent, {
    });
  }

  privacyPolicy() {
    const diag = this.dialog.open(PrivacyPolicyComponent, {
    });
  }

}
