import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { API } from '../../provider/api';
import { AppConstants } from '../../provider/appconstants';
import { MatDialog } from '@angular/material';
import { TermsAndConditionsComponent } from '../terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from '../privacy-policy/privacy-policy.component';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})

export class SignupComponent implements OnInit {
  options: FormGroup;
  signUpForm: FormGroup;
  showError: string;
  planname: string;
  clientBaseUrl: String;
  pay: boolean = false;
  payresponse: String;
  activity: string = "Sign Up";
  disable: boolean = false;
  isRef;
  constructor(fb: FormBuilder, signUp: FormBuilder, public router: Router, public api: API, public appConstants: AppConstants, public dialog: MatDialog) {

    this.clientBaseUrl = appConstants.baseURL;
    this.showError = this.getQueryValue('error');
    this.planname = this.getQueryValue('planname') == null ? "plan1" : this.getQueryValue('planname');
    
    this.isRef = localStorage.getItem("referralCode")

    this.options = fb.group({
      hideRequired: false,
      floatLabel: 'auto',
    });

    this.signUpForm = signUp.group({
      'name': [null, Validators.compose([Validators.required])],
      'email': [null, Validators.compose([Validators.required, Validators.email])],
      'password': [null, Validators.compose([Validators.required, Validators.minLength(6)])],
      'confirmPassword': [null, Validators.compose([Validators.required, Validators.minLength(6)])],
    });
  }
  ngOnInit() {

  }

  getQueryValue(key) {
    let sPageURL = window.location.search.substring(1);
    let sURLVariables = sPageURL.split('&');
    for (var i = 0; i < sURLVariables.length; i++) {
      var sParameterName = sURLVariables[i].split('=');
      if (sParameterName[0] == key) {
        return decodeURIComponent(sParameterName[1]);
      }
    }
    return null;
  }

  onFormSubmit(valid, e) {

    if (valid) {
      e.target.submit();

      // let data = {
      //   customerName: this.signUpForm.get('name').value,
      //   customerEmailId: this.signUpForm.get('email').value,
      //   customerPass: this.signUpForm.get('password').value
      // };
      // this.api.signUp(data)
      // .subscribe(response => {
      //   console.log(response);
      //   if (response["status"]) {

      //     this.router.navigate(['signin']);

      //   } else {
      //     this.showError = response["data"][0];
      //     console.log(response["data"]);
      //   }
      // }, (err) => {
      //   console.log(err);
      //   this.showError = err['error']['data'][0];
      // });
      if (this.isRef)
        localStorage.removeItem("referralCode");
    }
  }

  checkUserExistance() {
    //      console.log("asd");

    let credentials = {
      userName: this.signUpForm.get('email').value,
    };
    this.api.paymentStatus(credentials.userName)
      .subscribe(respone => {
        if (respone['status']) {
          this.pay = respone['status'];
          if (respone['data'][0] == "1") {
            //payment page
            this.activity =  "Payment";
            this.disable = false ;
            this.payresponse = respone['message'];
            this.signUpForm.controls['password'].setErrors(null);
            this.signUpForm.controls['confirmPassword'].setErrors(null);
          }
          //usesr already exists
          else if (respone['data'][0] == "2") {
            this.payresponse = respone['message'];
            this.activity =  "Sign up";
            this.disable = true ;
            // this.pay = false;
          }
          else{
            this.pay = false;
            this.payresponse = "";
          }
        }
        else {
          //
          this.pay = false;
          this.payresponse = "";
        }
      }, (err) => {
        console.log(err);
      }

      );
  }

  termsAndConditopns() {
    const diag = this.dialog.open(TermsAndConditionsComponent, {
    });
  }

  privacyPolicy() {
    const diag = this.dialog.open(PrivacyPolicyComponent, {
    });
  }
}  
