import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-account_settings',
  templateUrl: './account-setting.component.html',
  styleUrls: ['./account-setting.component.css']
})

export class AccountSettingsComponent implements OnInit {

  subscriptionType: String;
  planName: string;
  userName: string;
  isLoaded: boolean = false;
  validity: any;
  validFrom: any;
  validTo: any;
  expireDate: number;
  payment:number;
  constructor(public cookie: CookieService, public router: Router) {
    this.GrossPayment();
  }
  navLinks = [
    { link: "", path: "info", label: "Info" },
    { link: "", path: "pwd", label: "Password" },
    { link: "", path: "billing", label: "Billing" },
    // {link: "", path:"refferal", label : "Refferal"},
  ];
  navLinkNew = this.navLinks;
  ngOnInit() {
    const IsCookieExists: boolean = this.cookie.check('name');
    if (!IsCookieExists) {
      this.router.navigate(['signin']);
    }

    let userSubscription = JSON.parse(localStorage.getItem('userSubscription'));
    let userName = JSON.parse(localStorage.getItem('users'));
    if (this.isLoaded) {
      this.planName = userSubscription["subscriptionType"];
      this.userName = userName["customerName"];
      //console.log(this.planName, this.userName, "update" );
      this.validity = JSON.parse(localStorage.getItem('userSubscription'))['validity'];
      this.validFrom = new Date(JSON.parse(localStorage.getItem('userSubscription'))['validFrom']);
      this.validTo = new Date(JSON.parse(localStorage.getItem('userSubscription'))['validTo']);
      // console.log((this.validTo-this.validFrom)/(60*1000*60*24),(new Date).getTime());
      if ((this.validTo - this.validFrom) / (60 * 1000 * 60 * 24) == 14) {
        this.expireDate = Math.ceil((this.validTo - (new Date).getTime()) / (60 * 1000 * 60 * 24));
        // console.log("exp date --"+ Math.ceil(this.expireDate));

      }

    }
    this.isLoaded = true;
  }
  GrossPayment() {
    this.payment = JSON.parse(localStorage.getItem('userSubscription'))['paymentGross'];
    if(this.payment==0.00){
      // console.log("this.payment here");
      this.navLinkNew = this.navLinks.slice(0,2);
      // console.log(this.navLinkNew);
    }
  }

  onclick() {
    // console.log('clickinggg');
  }

  signOut() {
    //console.log('signOut');
    this.cookie.deleteAll();
    this.ngOnInit();
  }
  homePage() {
    this.router.navigate(['home']);
  }
}  
