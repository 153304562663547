import { Component, OnInit } from '@angular/core';
import {FormControl, Validators, FormGroup, FormBuilder} from '@angular/forms';
import { API } from '../../provider/api';

@Component({
  selector: 'app-reset-pw',
  templateUrl: './reset-pw.component.html',
  styleUrls: ['./reset-pw.component.css']
})
export class ResetPwComponent implements OnInit {

  mailPass : FormGroup;
  userlId : String ;
  email : String ;
  showError : String ;

  constructor(pass:FormBuilder , public api: API) {

    this.mailPass=pass.group({
      'email': [null,Validators.compose([Validators.email,Validators.required])]
    });
   }

   success=false;
   fail=false;

  ngOnInit() {
  }

  emailSend(){

    //console.log(this.mailPass.get('email').value);

    //let user = JSON.parse(localStorage.getItem('users'));

    
    this.email = this.mailPass.get('email').value;
    this.userlId=this.mailPass.get('email').value ;
    //this.userlId=this.email ;

    // console.log(this.userlId);
    
    // console.log(this.email);

    this.api.forgetPass(this.userlId,this.email).subscribe(
      response =>{
        if(response["status"]){
          this.showError = response["message"];
          this.mailPass.reset();  
          this.success=true;
          this.fail=false;
        }else{
          this.fail=true ;
          this.success=false ;
          this.mailPass.reset() ;
          
        }        
      },
      (err) =>{
        
        console.log(err);
        this.showError=err['error']['data'];

      }

    );
  }




  // IsVisible = false;
  
  // ShowHide(){
  //   this.IsVisible = true;
  // }

}

