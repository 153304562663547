import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-info-modals',
  templateUrl: './info-modals.component.html',
  styleUrls: ['./info-modals.component.scss']
})
export class InfoModalsComponent implements OnInit {
  title: string;
  content: any;
  constructor(public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<InfoModalsComponent>) { 

    this.title = data.title;
    this.content = data.content;
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }
}
