import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule,Validators } from '@angular/forms';
import {API} from '../../../provider/api';

@Component({
    selector: 'app-sentiments_pwd',
    templateUrl: './account-pwd.component.html',
    styleUrls: ['./account-pwd.component.css']
  })
  
export class AccountPwdComponent implements OnInit {
  options: FormGroup;
  updateForm: FormGroup;
  showMsg: String;
  constructor(fb: FormBuilder , public api : API, update: FormBuilder) {
    this.options = fb.group({
      hideRequired: false,
      floatLabel: 'auto',
    });
    this.updateForm = update.group({
      'oldpass': [null, Validators.compose([Validators.required])],
      'newpass': [null, Validators.compose([Validators.required, Validators.minLength(6)])],
      'confpass': [null, Validators.compose([Validators.required, Validators.minLength(6)])],
    });
  }
  ngOnInit() {
    
  }

  onFormSubmit(valid){
    if(valid){
      
      let user = JSON.parse(localStorage.getItem('users'));
      let credentials = {
        userName: user["customerId"],
        oldPassword: this.updateForm.get('oldpass').value,
        newPassword: this.updateForm.get('newpass').value
      };
    
      this.api.updatePassword(credentials)
        .subscribe(
          response => {
          if (response["status"]) {
            this.showMsg = response["message"];
            this.updateForm.reset();
            Object.keys(this.updateForm.controls).forEach((name) => {
              var control = this.updateForm.controls[name];
              control.setErrors(null);
            });
          } else {
            this.showMsg = response['data'][0];
          }
        }, 
        (err) => {
          console.log(err);
          this.showMsg = err['error']['data'][0];
        });
    }
  }
}  

