import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef,MatDialog, MAT_DIALOG_DATA} from '@angular/material';
// import { AppRoutingModule } from '../app-routing.module'
import { Router } from '@angular/router';


@Component({
  selector: 'exp--box',
  templateUrl: './exp-box.component.html',
  styleUrls: ['./exp-box.component.css'],
  // providers: [AccountSettingsComponent]
})
export class ExpBoxComponent implements OnInit {
  title: any;



  constructor(public dialog: MatDialogRef<ExpBoxComponent> , public diag: MatDialog ,   public router: Router ) {
 
  }

  ngOnInit() {
  }

  newPlan(){
    this.dialog.close();
    this.dialog.afterClosed().subscribe(result => {
      // this.selectedOption = result;
      // console.log("closingg")
      this.router.navigate(['new-plan']);
    });
  }
  exit(){
    this.dialog.close();
  }


}
