import { Component, OnInit, Inject } from '@angular/core';
import { API } from '../../provider/api';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AccountSettingsComponent } from '../account_settings/account-setting.component';
import { delay } from 'q';
import { CookieService } from 'ngx-cookie-service';
import { InfoModalsComponent } from '../info-modals/info-modals.component';

@Component({
  selector: 'app-plan-box',
  templateUrl: './plan-box.component.html',
  styleUrls: ['./plan-box.component.css'],
  providers: [AccountSettingsComponent]
})
export class PlanBoxComponent implements OnInit {

  user: [];
  planName: String;
  customerId: String;
  mes: boolean = false;
  planUpdate : boolean =false
 


  constructor(public dialog: MatDialog,public cookie : CookieService ,public diag:MatDialog, public dialogRefPlan: MatDialogRef<PlanBoxComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public api: API ) {
    this.planName = this.data.plan;
    this.updateData();
  }

  ngOnInit() {
  }

  updateData() {
    this.user = JSON.parse(localStorage.getItem("users"));
    this.customerId = this.user["customerId"];

  }

  savePlan() {
    this.mes = true;
    //console.log("updating plann");
    this.api.updatePlan(this.customerId, this.planName).subscribe(
      response => {
        if (response["status"]) {
          //console.log(this.customerId+"  "+this.planName) ;
          localStorage.setItem("users", JSON.stringify(response["data"][0]["customerInfoModel"]));
          localStorage.setItem("userSubscription", JSON.stringify(response["data"][0]["userSubscriptionModel"]));
          localStorage.setItem("customerSubscriptionLicense",JSON.stringify(response["data"][0]["customerSubscriptionLicense"]));
          
          // console.log( JSON.parse(localStorage.getItem('users'))['planCode']);
          this.cookie.set('plan', JSON.parse(localStorage.getItem('users'))['planCode']);
          this.updateData();
          delay(2000);
          this.cookie.set('changePlanTimer', "timerCookie",(10/(60*24)));
          this.planUpdate=true;
          // console.log(response["data"]);
        } else {
          // console.log(response["data"]);
          this.dialog.open(InfoModalsComponent, {
            width: "50vw",
            data: {
              title: "Update plan",
              content:response["data"]
    
            }
          });
        }
        // this.diag.closeAll();
       // this.dialogRefPlan.close();
    
      },
      (err) => {
        console.log(err);
        this.dialog.open(InfoModalsComponent, {
          width: "50vw",
          data: {
            title: "Fail to Update",
            content:err
  
          }
        });
        this.dialogRefPlan.close();
      }
    )
  }
  cancelPlanDialog() {
    // console.log("closing planBox");
     this.dialogRefPlan.close(false);
    //  this.diag.closeAll();
  }
  closeDialog(){
    this.diag.closeAll();
  }

}
