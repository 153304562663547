import { Component, OnInit } from '@angular/core';
// import { AngularFireFunctions } from '@angular/fire/function'

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
