import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, NgForm } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { API } from '../../provider/api';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { Options, ChangeContext } from 'ng5-slider';
import { DetailGraphComponent } from '../detail-graph/detail-graph.component';
import { InfoModalsComponent } from '../info-modals/info-modals.component';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { MatPaginator } from '@angular/material';
import { CookieService } from 'ngx-cookie-service';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { SelectPlanComponent } from '../select-plan/select-plan.component';
import { DeleteFilterComponent } from '../delete-filter/delete-filter.component';
import { empty } from 'rxjs';

export interface Cname {
  name: string;
}

export interface Filters {
  filterName: string;
  filterData: string;
  filterParam: string;
}


export interface RangeFilters {
  filterName: string;
  aliasStart: string;
  aliasEnd: string;
  value: number;
  highValue: number;
  options: Options;
  isDisplay: boolean;
}


export interface PeriodicElement {
  time: string;
  ticker: number;
  companyName: number;
  symbol: string;
}

export interface ModalInfo {
  modalTitle: string;
  modalContent: string;
}


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']

})


export class HomeComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  useremail: string;
  userfilter: any;
  notification: any;
  userName: string;
  totalRumours: number = 0;
  totalDeals: number = 0;
  totalSources: number = 0;
  totalSources1: number = 0;
  rumours: any;
  appliedFilter: string = "";
  // planType: String;
  validity: any;
  validFrom: any;
  validTo: any;
  expireDate: any;
  planName: string = "";
  cancelSubs: boolean = false;
  rumoursData: any;
  snpRumours: any;
  mostActiveSuperstar: any;
  uniqueRumours: any;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  getSnPRumours_spin = true;
  getMostActiveSuperstar_spin = true;
  getUniqueRumours_spin = true;
  getMostRumourActivity_spin = true;
  rumours_spin = true;
  mostRumorActivity: any;
  filterDuration: string;
  targetOption: string;
  sectors: any;
  states: any;
  ribbon: boolean = true;
  displayedColumns = ['position', 'name', 'weight', 'symbol'];
  dataSourceSnP: PeriodicElement[] = [];
  sortOrderData: string = "-startTimestamp";
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  // MatPaginator Inputs
  length = 100;
  pageSize = 25;
  pageFetchSize = 25;
  pageNumber = 1;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  // MatPaginator Output
  pageEvent: PageEvent;
  alertForm: FormGroup;
  updateForm: FormGroup;
  filterActionSec: boolean = false;
  filterUpdateSec: boolean = false;
  filterAddSec: boolean = false;
  searchValues: Cname[] = [

  ];
  filterValue: Filters[] = [
    // {filterName: 'helo', filterData: 'ascas'},
    // {filterName: 'helo1', filterData: 'ascas1'},

  ];

  filterKey = {
    "Source Popularity": "popularity",
    //"Total Rumors": "",
    //"Unique Acquirers":"",
    //"Max move since rumor":"hitPercentage",
    // "Target Stock Price": "acquiree.lastTrade",
    "Target Market Cap": "acquiree.marketCap",
    "Average Daily Volume": "acquiree.averageVolume",
    "Source Reliability Index": "reliabilityStars"
  };

  invertX = false;
  invertY = false;
  shown = "native";
  compact = true;
  disabled = false;

  rangeFilters: RangeFilters[] = [
    { filterName: 'Source Popularity', aliasStart: "popularity", aliasEnd: "popularity", value: 0, highValue: 0, options: { floor: 0, ceil: 10 }, isDisplay: false },
    // {filterName: 'Total Rumors', aliasStart: "acquiree.totalRumors", aliasEnd: "acquiree.totalRumors", value: 0, highValue: 0, options: {floor: 0, ceil: 200},isDisplay: false},
    // {filterName: 'Unique Acquirers', aliasStart: "",  aliasEnd: "", value: 0, highValue: 0, options: {floor: 0, ceil: 10},isDisplay: false},
    //{ filterName: 'Max Move since Rumor', aliasStart: "hitPercentage", aliasEnd: "hitPercentage", value: 0, highValue: 0, options: { floor: 0, ceil: 100 }, isDisplay: false },
    // {filterName: 'Total Rumors' , aliasStart:"acquiree.totalRumors" , aliasEnd:"acquiree.totalRumors" ,value: 0, highValue: 0, options: { floor: 0, ceil: 200 }, isDisplay: false },
    // {filterName: 'Unique Acquirers' , aliasStart:"" , aliasEnd:"" ,value: 0, highValue: 0, options: { floor: 0, ceil: 10 }, isDisplay: false },
    // {filterName: 'Time Range', aliasStart: "",aliasEnd: "", value: 0, highValue: 0, options: {floor: 0, ceil: 100},isDisplay: false},
    // {filterName: 'Source Credibility', aliasStart: "",aliasEnd: "", value: 0, highValue: 0, options: {floor: 0, ceil: 10},isDisplay: false},
    // {filterName: 'Price Change', aliasStart: "", aliasEnd: "", value: 0, highValue: 0, options: {floor: 0, ceil: 100},isDisplay: false},
    { filterName: 'Source Reliability Index', aliasStart: "reliabilityStars", aliasEnd: "reliabilityStars", value: 0, highValue: 0, options: { floor: 0, ceil: 5 }, isDisplay: false },
    // {
    //   filterName: 'Target Stock Price', aliasStart: "acquiree.lastTrade", aliasEnd: "acquiree.lastTrade", value: 0, highValue: 0,
    //   options:
    //   {
    //     floor: 0,
    //     ceil: 10000,

    //     translate: (value: number): string => {
    //       return '$' + value;
    //     },

    //   },
    //   isDisplay: false
    // },
    {
      filterName: 'Target Market Cap', aliasStart: "acquiree.marketCap", aliasEnd: "acquiree.marketCap", value: 0, highValue: 0,
      options:
      {
        floor: 0,
        ceil: 1000000,
        translate: (value: number): string => {
          return '$' + value + ' M';
        }
      },
      isDisplay: false
    },
    {
      filterName: 'Average Daily Volume', aliasStart: "acquiree.averageVolume", aliasEnd: "acquiree.averageVolume", value: 0, highValue: 0,
      options:
      {
        floor: 0,
        ceil: 1000,
        translate: (value: number): string => {
          return '$' + value;
        }
      },
      isDisplay: false
    },
    {
      filterName: 'Est. Price Impact', aliasStart: "estimatedPriceImpact1", aliasEnd: "estimatedPriceImpact2", value: -50, highValue: -50,
      options:
      {
        floor: -50,
        ceil: 50,
        translate: (value: number): string => {
          return value + " %";
        }
      },
      isDisplay: false
    },


  ];

  modalInfo: ModalInfo[] = [
    { modalTitle: 'Rumors Likely to Outperform S&P', modalContent: '<p>Rumors of target companies whose return is predicted to exceed the S&P 500 return by more than 3% in the 10 days following the rumor.</p>' },
    { modalTitle: 'Most Active Superstars in Last 30 Days', modalContent: '<p>Superstars are defined as the sources with the highest reliability scores (4 or 5 stars). This list shows the superstars that have posted most rumors in last 30 days.</p>' },
    { modalTitle: 'Unique Rumors', modalContent: '<p>Unique rumors are rumors on a target company that is either being mentioned for the first time, or has been mentioned again after a silence period of 15 days or longer.</p>' },
    { modalTitle: 'Most Rumors Activity in Last 7 Days', modalContent: '<p>Target companies that have received the most rumors in the last 7 Days.</p>' },
    { modalTitle: 'Source Reliability Index', modalContent: '<p>Reliability is a value that scales from 0-5 stars based on a source\'s ability to publish rumors that propagate to higher popularity sources in terms of both speed and breadth. High-reliability sources that may not be highly popular tend to exhibit high alpha content. A score of 5 stars indicates the highest reliability and a score of 0 stars indicates that a source has yet to build a track record.</p>' },
    { modalTitle: 'Est. Price Impact', modalContent: '<h3>What is Estimated Price Impact? </h3><p>Estimated Price Impact shows the highest confidence expected move of the target company\'s stock price relative to the price when the rumor was detected. This prediction is valid until a new rumor for the target company is detected, or 10 days after initial rumor detection -- whichever comes first.</p> <h3>How do I read the Prediction Progress Bars? </h3> <p>When the prediction is valid, the green area (red if the prediction is negative) represents the maximum price move that has been achieved given the predicted range since the rumor was detected.</p><p><img src="./assets/images/indicator.png" width="320px"></p><p>The Prediction Progress Bars will deactivate when the prediction expires. A rumor expires after 10 days from initial rumor detection, or immediately when a new rumor comes in during the 10-day period. </p><p><img src="./assets/images/indicator-disabled.png" width="320px"></p>  ' },
    { modalTitle: 'Source Popularity', modalContent: '<p>The popularity of a rumor is the credibility score of an individual source and is based on traffic, visitor demographics, social media influence, and bias-free expert curation.</p>' },
    { modalTitle: '', modalContent: '' }
  ]

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.searchValues.push({ name: value.trim() });
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.updateOutperform();
  }

  remove(cname: Cname): void {
    const index = this.searchValues.indexOf(cname);

    if (index >= 0) {
      this.searchValues.splice(index, 1);
    }
    if (this.pageEvent != undefined) {
      this.paginator.pageIndex = 0;
      this.pageNumber = 1;
    }
    this.getRumours();
  }

  constructor(public api: API, public dialog: MatDialog, public cookie: CookieService, public router: Router, filteralertForm: FormBuilder, updateForm: FormBuilder, ) {
    this.userName = this.cookie.get('name');
    this.useremail = this.cookie.get('acEmail');
    this.rumours = [];
    this.rumoursData = [];
    this.snpRumours = [];
    this.mostActiveSuperstar = [];
    this.uniqueRumours = [];
    this.mostRumorActivity = [];
    this.sectors = [];
    this.userfilter = [];
    this.notification = {};
    this.states = [];
    this.filterDuration = "7";
    this.targetOption = "1";

    this.alertForm = filteralertForm.group({
      'email': [null, Validators.compose([Validators.email])],
      'id': [null],
      'sms': [null],
      'emailCheck': [null],
      'smsCheck': [null]
    });
    this.updateForm = updateForm.group({
      'update': [null, Validators.compose([Validators.required])]
    });

    const IsCookieExists: boolean = this.cookie.check('name');
    if (!IsCookieExists) {
      this.router.navigate(['signin']);
    }
    // this.reload();
    // this.upgradeDisable();
    setInterval(() => { this.upgradeDisable(); }, (1000 * 60));
  }

  ngOnInit() {
    const IsCookieExists: boolean = this.cookie.check('name');
    if (!IsCookieExists) {
      this.router.navigate(['signin']);
    }
    this.callDetails();
    this.getFilterUsersData();
    this.getNotificationData();
    // console.log("oninittrtushfvjh...");
    this.validity = JSON.parse(localStorage.getItem('userSubscription'))['validity'];
    this.validFrom = new Date(JSON.parse(localStorage.getItem('userSubscription'))['validFrom']);
    this.validTo = new Date(JSON.parse(localStorage.getItem('userSubscription'))['validTo']);
    let customerSubscriptionLicense = JSON.parse(localStorage.getItem('customerSubscriptionLicense'));
    this.cancelSubs = customerSubscriptionLicense["cancelSubs"];
    // console.log((this.validTo-this.validFrom)/(60*1000*60*24),(new Date).getTime());
    if ((this.validTo - this.validFrom) / (60 * 1000 * 60 * 24) == 14) {
      this.expireDate = Math.ceil((this.validTo - (new Date).getTime()) / (60 * 1000 * 60 * 24));
      // console.log("exp date --"+ Math.ceil(this.expireDate));
    }
    this.planName = JSON.parse(localStorage.getItem('userSubscription'))['subscriptionType'];


    // setInterval(function(){this.callDetails()}, 7000);


    // this.appcuseCall();


  }

  appcuseCall() {

    this.router.events.subscribe(event => {
      console.log('current route: ', this.router.url.toString());
      if (event instanceof NavigationStart) {
        console.log("Route Change Start!");

      } else if (event instanceof NavigationEnd) {
        console.log("Route Change End!");
        // window.Appcues.page();
      }
    });

  }

  filterAction() {
    this.filterActionSec = true;
  }
  filterUpdateAction() {
    if (this.appliedFilter.trim() != "") {
      this.filterUpdateSec = true;
    } else {
      this.filterAddSec = true;
    }

  }

  closeFilter() {
    this.filterActionSec = false;
  }
  closeUpdate() {
    this.filterUpdateSec = false;
    this.filterAddSec = false;
  }
  cancelFilter() {
    this.filterActionSec = false;
    this.filterUpdateSec = false;
  }

  saveNewFilter() {
    this.filterUpdateSec = false;
    this.filterAddSec = true;
    this.appliedFilter = "";


    // this.filterUpdateSec = false;
    // this.filterAddSec = true;
    // this.appliedFilter = "";

  }


  getFilterUsersData() {
    this.api.getAllFilterData(this.useremail).subscribe(response => {

      this.userfilter = response["data"];
    }, (err) => {
      console.log(err);
    });
  }

  getNotificationData() {
    this.api.getNotification(this.useremail).subscribe(response => {

      this.notification = JSON.parse(response["data"][0]["notifications"]);
      this.notification["id"] = response["data"][0]["id"];

      this.alertForm.controls["email"].setValue(this.notification["EMAIL_ADDRESS"]);
      this.alertForm.controls["sms"].setValue(this.notification["USER_PHONE_NUMBER"]);
      this.alertForm.controls["emailCheck"].setValue(this.notification["EMAIL"]);
      this.alertForm.controls["smsCheck"].setValue(this.notification["SMS"]);
      this.alertForm.controls["id"].setValue(this.notification["id"]);
      console.log(response);
    }, (err) => {
      console.log(err);
    });
  }

  //rahul,muku
  removefilter(event, index, data) {
    event.stopPropagation();
    let filter = {
      'custID': this.useremail,
      'filterName': data['filterName'],
      'id': data['id']
    }

    // console.log(index, event, filter);
    this.deletefilter(filter);

  }

  //rahul
  deletefilter(deletefilter) {
    const diag = this.dialog.open(DeleteFilterComponent, {
      data: { filter: deletefilter }
    });

    diag.afterClosed().subscribe(result => {
      this.getFilterUsersData();

      this.filterCancelAll();
    });
    return;
  }


  callDetails() {

    this.getSectors();
    this.getStates();
    this.getRumours();
    this.getSnPRumours();
    this.getMostActiveSuperstar();
    this.getUniqueRumours();
    this.getMostRumourActivity();
    this.getCounts();
    setTimeout(() => this.callDetails(), 420000);

  }

  onChange(event, index) {

    event.stopPropagation();

    // console.log("before ",data['STATUS']);
    //  let status = !data['STATUS'];

    this.userfilter[index]['status'] = this.userfilter[index]['status'] == 0 ? 1 : 0;
    let filter = {
      'custID': this.useremail,
      'filterName': this.userfilter[index]['filterName'],
      'status': this.userfilter[index]['status']
    }
    this.closeFilter();
    //  console.log(data['STATUS']);
    // console.log(this.userfilter);
    // console.log(index, event, filter);
    this.api.updateFilterStatus(filter, this.userfilter[index]['id']).subscribe(response => {
      // console.log(response);
      // console.log(this.userfilter);
      this.dialog.open(InfoModalsComponent, {
        width: "50vw",
        data: {
          title: "Alert Status Update",
          content: this.userfilter[index]['filterName'] + " status updated"

        }
      });
    }, (err) => {
      console.log(err);
    });
    // event.stopPropagation();
  }

  updateFilter() {

    let notify = {
      "custID": this.useremail,
      "notifications": JSON.stringify({
        "EMAIL": this.alertForm.get('emailCheck').value == null ? false : this.alertForm.get('emailCheck').value,
        "SMS": this.alertForm.get('smsCheck').value == null ? false : this.alertForm.get('smsCheck').value,
        "USER_PHONE_NUMBER": this.alertForm.get('sms').value,
        "EMAIL_ADDRESS": this.alertForm.get('email').value
      })

    }
    // console.log(notify);

    this.api.updateNotify(notify, this.alertForm.get('id').value).subscribe(response => {
      this.closeFilter();
      const diag = this.dialog.open(InfoModalsComponent, {
        width: "50vw",
        data: {
          title: "Notification's Update",
          content: "Notification Details are Updated"
        }
      });
      console.log(diag, "diaglog")
      diag.afterClosed().subscribe(result => {
        console.log("this diag is closed and data updated")
        this.getNotificationData();
      });
    }, (err) => {
      console.log(err);
    });


  }


  makecancle(data) {
    if (data['filterName'] == 'All' || data['filterName'] == 'ALL') {
      return false;
    }
    else {
      return true;
    }
  }

  addFilter() {
    // var filterNameObj = "";
    // console.log(this.updateForm.get('update').value);


    if (this.appliedFilter.toLowerCase() == 'all' || this.appliedFilter.toLowerCase() == 'default') {
      console.log("cannot update filter")
      this.dialog.open(InfoModalsComponent, {
        width: "50vw",
        data: {
          title: "Watchlist Update",
          content: ("You cannot update " + this.appliedFilter + " filter. You can only edit custom filters.")
        }
      });
      return;
    }
    var filterNameObj = ""
    var filterObj = {};
    // console.log(this.appliedFilter);
    if (this.appliedFilter != "") {
      filterNameObj = this.appliedFilter;
      // console.log(this.appliedFilter);
    } else {
      filterNameObj = this.updateForm.get('update').value;

      for (let data of this.userfilter) {
        // console.log(data);
        filterNameObj = this.updateForm.get('update').value;
        if (filterNameObj == data.filterName) {
          // console.log("cannot save filter")
          this.dialog.open(InfoModalsComponent, {
            width: "50vw",
            data: {
              title: "Watchlist Update",
              content: ("Filter " + this.appliedFilter + " already exists.")
            }
          });
          return;
        }
      }
      // console.log("asdds",this.appliedFilter)
      if (this.updateForm.get('update').value == null || this.updateForm.get('update').value == "") {
        // console.log("filter name not specified");
        this.dialog.open(InfoModalsComponent, {
          width: "50vw",
          data: {
            title: "Watchlist Update",
            content: ("You cannot have null filter.")
          }
        });
        return;
      }


    }


    if (this.filterValue.length > 0) {
      this.filterValue.map(data => {
        if (data.filterName == "Sector / Industries") {
          filterObj["sectorIndustry"] = data.filterData;
        } else if (data.filterName == "Target Company Location") {
          filterObj["state"] = data.filterData;
        } else {
          filterObj[data.filterName] = {
            min: parseInt(data.filterData.split("-")[0].trim()),
            max: parseInt(data.filterData.split("-")[1].trim()),
          }
        }

      });
    }

    // if (this.filterValue.length > 0) {
    //   this.filterValue.map(data => {
    //     if (data.filterName == "Target Company Location") {
    //       filterObj["states"] = data.filterData;
    //     } else {
    //       filterObj[data.filterName] = {
    //         min: data.filterData.split("-")[0].trim(),
    //         max: data.filterData.split("-")[1].trim(),
    //       }
    //     }

    //   });
    // }

    if (this.searchValues.length > 0) {
      var tickerbaseObj = [];
      this.searchValues.map(data => {
        tickerbaseObj.push(data.name);
      })
      filterObj["tickers"] = tickerbaseObj;
    }

    //console.log(this.useremail, filterNameObj, filterObj);

    let filter = {
      'custID': this.useremail,
      'filterName': filterNameObj,
      'status': 1,
      'filterData': JSON.stringify(filterObj)
    }

    this.api.addFilter(filter).subscribe(response => {
      // console.log(response);
      this.closeUpdate();
      this.getFilterUsersData();
      this.dialog.open(InfoModalsComponent, {
        width: "50vw",
        data: {
          title: "Watchlist Update",
          content: this.appliedFilter != "" ? (filterNameObj + " is updated") : (filterNameObj + " is created")
        }
      });
      this.updateForm.controls["update"].setValue("");
      // this.updateForm.setValue("");
    }, (err) => {
      console.log(err);
    }
    );
    // }


  }
  clearfilter() {
    for (let filterdata of this.rangeFilters) {

      if (filterdata.filterName == "Est. Price Impact") {
        filterdata.highValue = -50;
        filterdata.value = -50;
      } else {
        filterdata.highValue = 0;
        filterdata.value = 0;
      }
      // console.log(this.filterValue, this.rangeFilters);

    }
  }

  clickFilter($event, data) {

    this.clearfilter();
    // this.filterCancelAll();

    this.appliedFilter = data.filterName;
    this.filterValue = [];


    for (let fname in data.filterData) {

      if (fname == "tickers" && data.filterData["tickers"].length > 0) {
        this.targetOption = "1";
        this.searchValues = [];
        data.filterData["tickers"].forEach(element => {
          this.searchValues.push({ name: element.trim() });
        });
      } else if (fname == "sectorIndustry" && data.filterData["sectorIndustry"].length > 0) {
        let industryObj = data.filterData["sectorIndustry"].split("-")[1].trim();
        this.filterValue.push({
          filterName: "Sector / Industries",
          filterData: data.filterData["sectorIndustry"],
          filterParam: "acquiree.industry:eq:" + industryObj
        });
      } else if (fname == "state" && data.filterData["state"].length > 0) {
        let stateObj = data.filterData["state"].replace(",", "|");
        this.filterValue.push({
          filterName: "Target Company Location",
          filterData: data.filterData["state"],
          filterParam: "acquiree.state:in:" + stateObj
        });
      } else if (this.filterKey.hasOwnProperty(fname)) {
        var filterData = data.filterData[fname];
        this.filterValue.push({
          filterName: fname,
          filterData: filterData.min + " - " + filterData.max,
          filterParam: this.filterKey[fname] + ":gte:" + filterData.min + "," + this.filterKey[fname] + ":lte:" + filterData.max,
        });
      }
    }
    this.closeFilter();
    this.getRumours();
    //   event. stopPropagation();
  }
  emailcheckbox() {
    // console.log('email')
    if (this.notification['EMAIL'] == true) {
      return true;
    }
    else {
      return false;
    }
  }

  smscheckbox() {
    // console.log('sms')
    return true;
  }

  slideConfig = {
    "slidesToShow": 3,
    "slidesToScroll": this.planName == "STANDARD" ? 0 : 1,
    infinite: false,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }

    ]
  };

  filterRemove(i) {

    // console.log(this.filterValue.slice(i)[0].filterName)

    let data = this.filterValue.slice(i)[0].filterName;
    // console.log(data);
    for (let filterdata of this.rangeFilters) {
      if (data == filterdata.filterName) {
        if (filterdata.filterName == "Est. Price Impact") {
          filterdata.highValue = -50;
          filterdata.value = -50;
        } else {
          filterdata.highValue = 0;
          filterdata.value = 0;
        }
      }
    }
    this.filterValue.splice(i, 1)
    // console.log("sadasdas",this.rangeFilters)

    // this.filterValue.splice(i, 1);

    if (this.pageEvent != undefined) {
      this.paginator.pageIndex = 0;
      this.pageNumber = 1;
    }
    this.getRumours();
  }

  detailsGraph(ticker, flag) {
    this.dialog.open(DetailGraphComponent, {
      width: flag == 1 ? "80vw" : "60vw",
      height: "95vh",
      data: {
        ticker: ticker,
        fromFlag: flag
      }
    });
  }
  // @HostListener("click", ["$event"])
  infoModals(i, event) {
    // event.preventDefault();
    event.stopPropagation();
    this.dialog.open(InfoModalsComponent, {
      width: "50vw",
      data: {
        title: this.modalInfo[i].modalTitle,
        content: this.modalInfo[i].modalContent

      }
    });
  }

  getRumours() {
    this.rumours_spin = true;
    // console.log(this.pageEvent);
    // console.log(this.pageEvent != undefined ? (this.pageEvent.pageIndex + 1) : 1);
    var getParam = "withTotalRumor=1&sort=" + this.sortOrderData + "&size=" + this.pageFetchSize + "&page=" + this.pageNumber;
    getParam += "&lastDays=0";// + this.filterDuration;
    var filter = "";
    if (this.searchValues.length > 0) {
      switch (this.targetOption) {
        case "1":
          filter += "acquiree.ticker:" + (this.searchValues.length > 1 ? 'in' : 'eq') + ":"
          this.searchValues.map(data => {
            filter += data.name + "|";
          });
          filter = filter.substring(0, filter.length - 1);
          break;
        case "2":
          filter += "acquirer.ticker:" + (this.searchValues.length > 1 ? 'in' : 'eq') + ":"
          this.searchValues.map(data => {
            filter += data.name + "|";
          });
          filter = filter.substring(0, filter.length - 1);
          break;
        case "3":
          filter += "acquiree.ticker:" + (this.searchValues.length > 1 ? 'in' : 'eq') + ":"
          this.searchValues.map(data => {
            filter += data.name + "|";
          });
          filter = filter.substring(0, filter.length - 1);
          filter += ",acquirer.ticker:" + (this.searchValues.length > 1 ? 'in' : 'eq') + ":"
          this.searchValues.map(data => {
            filter += data.name + "|";
          });
          filter = filter.substring(0, filter.length - 1);
          break;
      }

      if (this.filterValue.length > 0) {
        filter += ",";
      }
    }
    if (this.filterValue.length > 0) {
      this.filterValue.map(data => {
        filter += data.filterParam + ",";
      });
      filter = filter.substring(0, filter.length - 1);
    }
    filter = encodeURIComponent(filter);

    getParam += filter.length > 0 ? "&filters=" + filter : "";



    this.api.getRumours(getParam).subscribe(response => {
      this.rumours = response["data"];
      this.rumoursData = response["data"];
      this.rumours_spin = false;
      this.length = response['total'];
    }, (err) => {
      console.log(err);
      this.rumours_spin = false;
    });
  }

  getSnPRumours() {
    this.getSnPRumours_spin = true;
    this.api.getSnPRumours().subscribe(response => {
      this.snpRumours = response["data"];
      this.getSnPRumours_spin = false;
    }, (err) => {
      console.log(err);
    });
  }
  getMostActiveSuperstar() {
    this.getMostActiveSuperstar_spin = true;
    this.api.getMostActiveSuperstar().subscribe(response => {
      this.mostActiveSuperstar = response["data"];
      this.getMostActiveSuperstar_spin = false;
    }, (err) => {
      console.log(err);
    });
  }
  getUniqueRumours() {
    this.getUniqueRumours_spin = true;
    this.api.getUniqueRumours().subscribe(response => {
      this.uniqueRumours = response["data"];
      this.getUniqueRumours_spin = false;
    }, (err) => {
      console.log(err);
    });
  }
  getMostRumourActivity() {
    this.getMostRumourActivity_spin = true;
    this.api.getMostRumourActivity().subscribe(response => {
      this.mostRumorActivity = response["data"];
      this.getMostRumourActivity_spin = false;
    }, (err) => {
      console.log(err);
    });
  }

  getSectors() {
    this.api.getSectors().subscribe(response => {
      this.sectors = response["data"];
      // console.log(this.sectors.sort(this.compare));
      //  console.log( this.sectors);
    }, (err) => {
      console.log(err);
    });
  }
  getStates() {
    this.api.getStates().subscribe(response => {
      this.states = response["data"];
      // console.log(this.states,"satteseddsadasd")
    }, (err) => {
      console.log(err);
    });
  }


  compare(a, b) {
    const sectorA = a.sector.toUpperCase();
    const sectorB = b.sector.toUpperCase();

    let comparison = 0;
    if (sectorA > sectorB) {
      comparison = 1;
    } else if (sectorA < sectorB) {
      comparison = -1;
    }
    return comparison;
  }


  playAudio(){
    let audio = new Audio();
    audio.src = "../../assets/audio/new_rumor.wav";
    audio.load();
    audio.play();
  }
  

  getCounts() {
    this.api.getDealsCount().subscribe(response => {
      this.totalDeals = response["total"];
      //  console.log( this.sectors);
    }, (err) => {
      console.log(err);
    });
    this.api.getRumoursCount().subscribe(response => {
      if (response && response["total"] && response["total"] > this.totalRumours && this.totalRumours != 0) {
        //play sound
        this.playAudio();
      }
      this.totalRumours = response["total"];
      //  console.log( this.sectors);
    }, (err) => {
      console.log(err);
    });
    this.api.getScrapers().subscribe(response => {
      this.totalSources = response["total"];
      //  console.log( this.sectors);
    }, (err) => {
      console.log(err);
    });
    this.api.getDealsCount().subscribe(response => {
      this.totalSources1 = response["total"];
      //  console.log( this.sectors);
    }, (err) => {
      console.log(err);
    });
  }

  getDateFormated(dateText) {

    var date = new Date(dateText - 14400000);
    return ((date.getUTCMonth() + 1).toString().length == 1 ? "0" + (date.getUTCMonth() + 1).toString() : (date.getUTCMonth() + 1).toString()) + "-" +
      (date.getUTCDate().toString().length == 1 ? "0" + date.getUTCDate().toString() : date.getUTCDate().toString()) + " " +
      (date.getUTCHours().toString().length == 1 ? "0" + date.getUTCHours().toString() : date.getUTCHours().toString()) + ":" +
      (date.getUTCMinutes().toString().length == 1 ? "0" + date.getUTCMinutes().toString() : date.getUTCMinutes().toString());
  }

  getDateFormatedY(dateText) {
    // console.log(dateText);
    var date = new Date(dateText - 14400000);
    return (date.getUTCFullYear().toString().length == 1 ? "0" + (date.getUTCFullYear()).toString() : date.getUTCFullYear().toString()) + "-" +
      ((date.getUTCMonth() + 1).toString().length == 1 ? "0" + (date.getUTCMonth() + 1).toString() : (date.getUTCMonth() + 1).toString()) + "-" +
      (date.getUTCDate().toString().length == 1 ? "0" + date.getUTCDate().toString() : date.getUTCDate().toString()) + " " +
      (date.getUTCHours().toString().length == 1 ? "0" + date.getUTCHours().toString() : date.getUTCHours().toString()) + ":" +
      (date.getUTCMinutes().toString().length == 1 ? "0" + date.getUTCMinutes().toString() : date.getUTCMinutes().toString());
  }

  getRumourStatus(dateText) {
    var rDate = new Date(dateText);
    var ndate = new Date();
    return (Math.ceil(Math.abs(ndate.getTime() - rDate.getTime()) / (1000 * 60 * 60 * 24)) > 10);

  }


  updateOutperform() {
    if (this.pageEvent != undefined) {
      this.paginator.pageIndex = 0;
      this.pageNumber = 1;
    }
    this.getRumours();
  }
  menuToggle(index, status) {
    this.rangeFilters[index].isDisplay = status;
  }
  roundValue(value) {
    // console.log(value);
    return Math.round(value / 10) / 100;
  }
  valueChange(index): void {

    let currFilter = this.rangeFilters[index];
    //  console.log(currFilter.filterName, currFilter.value, currFilter.highValue)
    var flag = false;
    var valueObj = currFilter.value;
    var highValueObj = currFilter.highValue;
    if (currFilter.filterName == "Target Market Cap") {
      valueObj = currFilter.value * 1;
      highValueObj = currFilter.highValue * 1;
    }
    this.filterValue.map(data => {
      if (data.filterName == currFilter.filterName) {
        data.filterData = currFilter.value + " - " + currFilter.highValue;
        data.filterParam = currFilter.aliasStart + ":gte:" + valueObj + "," + currFilter.aliasEnd + ":lte:" + highValueObj;
        flag = true;
      }
    });
    if (flag) {
      if (this.pageEvent != undefined) {
        this.paginator.pageIndex = 0;
        this.pageNumber = 1;
      }
      this.getRumours();
    } else {
      this.filterValue.push({
        filterName: currFilter.filterName,
        filterData: currFilter.value + " - " + currFilter.highValue,
        filterParam: currFilter.aliasStart + ":gte:" + valueObj + "," + currFilter.aliasEnd + ":lte:" + highValueObj,
      });
      if (this.pageEvent != undefined) {
        this.paginator.pageIndex = 0;
        this.pageNumber = 1;
      }
      this.getRumours();
    }
  }
  filterCancelAll() {
    // console.log(this.filterValue, this.rangeFilters);
    // for (let data of this.filterValue) {
    for (let filterdata of this.rangeFilters) {

      if (filterdata.filterName == "Est. Price Impact") {
        filterdata.highValue = -50;
        filterdata.value = -50;
      } else {
        filterdata.highValue = 0;
        filterdata.value = 0;
      }
      // console.log(this.filterValue, this.rangeFilters);

    }
    //  }
    // console.log(this.filterValue, this.rangeFilters);
    this.filterValue = [];
    // console.log(this.filterValue);
    if (this.pageEvent != undefined) {
      this.paginator.pageIndex = 0;
      this.pageNumber = 1;
    }
    this.appliedFilter = "";


    this.getRumours();
  }


  addSectorsToFilter(sector, industry) {
    var flag = false;
    this.filterValue.map(data => {
      if (data.filterName == "Sector / Industries") {
        data.filterData = sector + " - " + industry;
        data.filterParam = "acquiree.industry:eq:" + industry;
        flag = true;
        return;
      }
    });
    if (flag) {
      if (this.pageEvent != undefined) {
        this.paginator.pageIndex = 0;
        this.pageNumber = 1;
      }
      this.getRumours();
      return;
    }
    this.filterValue.push({
      filterName: "Sector / Industries",
      filterData: sector + " - " + industry,
      filterParam: "acquiree.industry:eq:" + industry
    });
    if (this.pageEvent != undefined) {
      this.paginator.pageIndex = 0;
      this.pageNumber = 1;
    }
    this.getRumours();
  }

  addStateFilter(state) {

    var flag = false;
    this.filterValue.map(data => {
      if (data.filterName == "Target Company Location") {
        data.filterData = data.filterData + "," + state;
        data.filterParam = data.filterParam.replace(":eq:", ":in:") + "|" + state;//"acquiree.state:in:" + state;
        flag = true;
        return;
      }
    });
    if (flag) {
      if (this.pageEvent != undefined) {
        this.paginator.pageIndex = 0;
        this.pageNumber = 1;
      }
      this.getRumours();
      return;
    }
    this.filterValue.push({
      filterName: "Target Company Location",
      filterData: state,
      filterParam: "acquiree.state:eq:" + state
    });
    if (this.pageEvent != undefined) {
      this.paginator.pageIndex = 0;
      this.pageNumber = 1;
    }
    this.getRumours();
  }

  sortData(sort: Sort) {
    // event.preventDefault();
    // event.stopPropagation();
    const isAsc = sort.direction === 'asc';
    switch (sort.active) {
      case 'target':
        this.sortOrderData = (isAsc ? "" : "-") + "acquiree.companyName";
        break;
      case 'ticker':
        this.sortOrderData = (isAsc ? "" : "-") + "acquiree.ticker";
        break;
      case 'popularity':
        this.sortOrderData = (isAsc ? "" : "-") + "popularity";
        break;
      case 'totalRumors':
        this.sortOrderData = (isAsc ? "" : "-") + "acquiree.totalRumors";
        break;
      case 'stars':
        this.sortOrderData = (isAsc ? "" : "-") + "reliability";
        break;
      case "rumortime":
        this.sortOrderData = (isAsc ? "" : "-") + "startTimestamp";
        break;
      default: this.sortOrderData = "-id";
        break;
    }
    if (this.pageEvent != undefined) {
      this.paginator.pageIndex = 0;
      this.pageNumber = 1;
    }
    this.getRumours();
    // if (!sort.active || sort.direction === '') {
    //   this.rumours = this.rumoursData;
    //   return;
    // }

    // this.rumours = this.rumoursData.sort((a, b) => {
    //   const isAsc = sort.direction === 'asc';
    //   switch (sort.active) {
    //     case 'target': return compare(a.acquiree.companyName, b.acquiree.companyName, isAsc);
    //     case 'ticker': return compare(a.acquiree.ticker, b.acquiree.ticker, isAsc);
    //     case 'popularity': return compare(a.popularity, b.popularity, isAsc);
    //     case 'totalRumors': return compare(a.acquiree.totalRumors, b.acquiree.totalRumors, isAsc);
    //     case 'stars': return compare(a.reliabilityStars, b.reliabilityStars, isAsc);
    //     default: return 0;
    //   }
    // });
  }


  showFilter(filterName) {
    if ((this.validTo - this.validFrom) / (60 * 1000 * 60 * 24) == 14) {
      return true;
    }
    if ((this.planName == "STANDARD" && (filterName == 'Est. Price Impact' || filterName == "Source Reliability Index"))
      || (this.planName == "PREMIUM" && filterName == 'Est. Price Impact')) {
      return false;
    }
    return true;

  }

  convertToCommaString(number) {
    return number.toLocaleString('en');
  }

  getServerData(event?: PageEvent) {
    //console.log(event);
    this.pageFetchSize = event.pageSize;
    this.pageNumber = event.pageIndex + 1;
    this.getRumours();
    return event;
  }

  signOut() {
    //console.log('signOut');
    this.cookie.deleteAll();
    this.reload();
  }



  tooltipData(data) {
    // planType : string ="";

    // console.log(this.planName);
    if (this.planName == 'PRO' || this.expireDate <= 14) {

      if (data.estimatedPriceImpact1 == 0 && data.estimatedPriceImpact2 == 0) {

        return "NA.";
      }
      else {
        // cosnsole.log("if is not  riunning");
        if (data.hitPercentage == -100) {
          return "Prediction Expired \nPrice When Rumor Detected: $" + data.priceWhenRumorDetected + "\nEstimated Price Impact: " + data.estimatedPriceImpact1 + "% - " + data.estimatedPriceImpact2 + " %\nMax. Move Since Rumor:N/A";
        } else {
          if (this.getRumourStatus(data.startTimestamp)) {
            if ((data.estimatedPriceImpact1 < 0 && data.estimatedPriceImpact2 > 0) || (data.estimatedPriceImpact1 >= 0 && data.hitPercentage > data.estimatedPriceImpact1) || (data.estimatedPriceImpact1 < 0 && data.hitPercentage < data.estimatedPriceImpact2))
              return "Prediction Achieved \nPrice When Rumor Detected: $" + data.priceWhenRumorDetected + "\nEstimated Price Impact: " + data.estimatedPriceImpact1 + "% - " + data.estimatedPriceImpact2 + " %\nMax. Move Since Rumor:  $" + data.maxPositiveMove + "  (" + data.hitPercentage + "%)";
            else
              return "Prediction Expired \nPrice When Rumor Detected: $" + data.priceWhenRumorDetected + "\nEstimated Price Impact: " + data.estimatedPriceImpact1 + "% - " + data.estimatedPriceImpact2 + " %\nMax. Move Since Rumor: $" + data.maxPositiveMove + "  (" + data.hitPercentage + "%)";

          } else
            return "Current Price: $" + data.lastTradedPrice + "\nPrice When Rumor Detected: $" + data.priceWhenRumorDetected + "\nEstimated Price Impact: " + data.estimatedPriceImpact1 + "% - " + data.estimatedPriceImpact2 + " %\nMax. Move Since Rumor: $" + data.maxPositiveMove + "  (" + data.hitPercentage + "%)";
        }
      }

    } else {
      return "Upgrade To Pro!"
    }
  }


  getBarString(data) {
    if (data.hitPercentage == -100) {
      return data.estimatedPriceImpact1 + "% - " + data.estimatedPriceImpact2 + "%";
    } else {
      if (this.getRumourStatus(data.startTimestamp)) {
        if ((data.estimatedPriceImpact1 < 0 && data.estimatedPriceImpact2 > 0) || (data.estimatedPriceImpact1 >= 0 && data.hitPercentage > data.estimatedPriceImpact1) || (data.estimatedPriceImpact1 < 0 && data.hitPercentage < data.estimatedPriceImpact2))
          return "Achieved " + Math.round(data.hitPercentage).toFixed() + "%";
        else {
          return data.estimatedPriceImpact1 + "% - " + data.estimatedPriceImpact2 + "%";
        }
      } else if (data.estimatedPriceImpact1 == 0 && data.estimatedPriceImpact2 == 0) {
        return "Computing";
      } else {
        return data.estimatedPriceImpact1 + "% - " + data.estimatedPriceImpact2 + "%";
      }
    }
  }

  remribbon() {
    //   // Initialize hideMe variable
    //   console.log(this.ribbon);
    // // this.ribbon = true;
    if (this.planName == 'PRO') { }
    this.ribbon = false;
    // console.log("remove ribbon");
    // return false;
  }

  Upgradeplan() {

    // console.log("upgrade plan");
    {
      const dialogRef = this.dialog.open(SelectPlanComponent, {
        width: '95%',
        height: '100%',
      });

      dialogRef.afterClosed().subscribe(result => {
        this.reload();
        this.upgradeDisable();
      });
    }


  }

  upgradeDisable() {
    const IsCookieExists: boolean = this.cookie.check('changePlanTimer');
    if (!IsCookieExists) {
      return false;
    } else
      return true;

  }

  checkpremium() {
    if (this.planName == "PREMIUM") {
      if (this.expireDate <= 14) {
        return true;
      }
      return false;
    }
    else {
      return true;
    }
  }

  checkstandard() {
    if (this.planName == 'STANDARD') {
      if (this.expireDate <= 14) {
        return true;
      }
      return false;
    }
    else {
      return true;
    }
  }

  checkplan() {

    if (this.planName == 'PRO' || this.expireDate <= 14) {
      return true;
    }
    else {
      return false;
    }

  }

  reload() {
    // this.router.navigate(['home']);
    this.ngOnInit();
    console.log("reloadsdaiasndas");

  }

  // updateData(){
  //   let userSubscriptionModel = JSON.parse(localStorage.getItem('userSubscription'));
  //   this.billingHistory = JSON.parse(localStorage.getItem('billingHistories'));
  //   let customerSubscriptionLicense = JSON.parse(localStorage.getItem('customerSubscriptionLicense'))
  //   this.nextPayment = localStorage.getItem('nextPaymentDate');
  //   this.planType = userSubscriptionModel["subscriptionType"];
  //   this.lastFourCard = localStorage.getItem('customerCardNumber');
  //   this.cardName = localStorage.getItem('customerCardName');
  //   this.cancelSubs = customerSubscriptionLicense["cancelSubs"];
  //   // this.isCance = customerSubscriptionLicense["cancelSubs"];
  //   console.log(this.cancelSubs);
  //   this.accSetting.ngOnInit();

  // }

}

// function compare(a: number | string, b: number | string, isAsc: boolean) {
//   return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
// }
