import { Component, OnInit } from '@angular/core';
//import {Api} from '../provider/api';
import { MatDialog, MatDialogRef, MatButtonToggleChange } from '@angular/material';
import { PlanBoxComponent } from 'src/app/plan-box/plan-box.component';
import { disableDebugTools } from '@angular/platform-browser';

@Component({
  selector: 'app-select-plan',
  templateUrl: './select-plan.component.html',
  styleUrls: ['./select-plan.component.css']
})
export class SelectPlanComponent implements OnInit {

  res: string;
  currentPlan: String;
  user: [];
  toggle: boolean = true;
  

  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<SelectPlanComponent>) {
   
   
    this.updateData();
  }

  ngOnInit() {
    if(this.currentPlan == "plan4" || this.currentPlan =="plan5" || this.currentPlan == "plan6"){
      this.toggle=false;
    }
    // console.log(this.toggle);
  }

  confirmBox(planName) {
    const diag = this.dialog.open(PlanBoxComponent, {
      width: '30%',
      data: { plan: planName }
    });
    diag.afterClosed().subscribe(result => {
      this.res = result;
      // console.log(this.res);
       if (result != false){
         this.dialog.closeAll();
       }
    });

  }
  updateData() {
    this.user = JSON.parse(localStorage.getItem('users'));
    this.currentPlan = this.user["planCode"];
    //  console.log(this.currentPlan);

  }
  cancelPlan() {
    this.dialogRef.close();
  }
  toggleView(change: MatButtonToggleChange){
    this.toggle = change.value;
  }
}