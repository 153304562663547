import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Form } from '@angular/forms';
import { AppConstants } from '../../../provider/appconstants';
import { API } from '../../../provider/api';

import { CookieService } from 'ngx-cookie-service';
import { AccountSettingsComponent } from '../account-setting.component';

@Component({
  selector: 'app-sentiments_info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.css']
})

export class AccountInfoComponent implements OnInit {
  options: FormGroup
  countryList: Array<String>;
  name: String;
  email: String;
  phone: String;
  organization: String;
  city: String;
  state: String;
  country: String;
  showError: String;
  user: [];
  customerId: string;

  constructor(fb: FormBuilder,
    public appContants: AppConstants,
    public api: API,
    private cookie: CookieService,
    public accSetting: AccountSettingsComponent) {

    this.countryList = appContants.countryArray;
    //console.log(JSON.parse(localStorage.getItem('users')));
    this.options = fb.group({
      hideRequired: false,
      floatLabel: 'auto',
    });

  }
  ngOnInit() {
    //  this.cookie.set('acEmail','kdubey080@gmail.com');
    // if(localStorage.getItem('users') != null){
    this.getUsersData();
    // }

  }

  getUsersData() {

    // let custId = localStorage.getItem('customerId');


    let custId = this.cookie.get('acEmail');
    // console.log(custId);
    this.api.getUserData(custId).subscribe(response => {
      if (response["status"]) {
        localStorage.setItem("users", JSON.stringify(response["data"][0]["customerInfoModel"]));
        localStorage.setItem("userSubscription", JSON.stringify(response["data"][0]["userSubscriptionModel"]));
        localStorage.setItem("billingHistories", JSON.stringify(response["data"][0]["billingHistories"]));
        localStorage.setItem("customerCardName", response["data"][0]["customerCardName"]);
        localStorage.setItem("customerCardNumber", response["data"][0]["customerCardNumber"]);
        localStorage.setItem("nextPaymentDate", response["data"][0]["nextPaymentDate"]);
        localStorage.setItem("customerSubscriptionLicense", JSON.stringify(response["data"][0]["customerSubscriptionLicense"]));
        this.assignToInput();


      } else {
        this.showError = response['data'][0];
      }
    }, (err) => {
      console.log(err);
      this.showError = err['error']['data'][0];
    });
  }

  assignToInput() {
    // console.log(this.customerId);

    this.user = JSON.parse(localStorage.getItem('users'));
    this.name = this.user["customerName"];
    this.email = this.user["customerEmailId"];
    this.phone = this.user["customerPhone"];
    this.organization = this.user["organizationName"];
    this.city = this.user["city"];
    this.state = this.user["state"];
    this.country = this.user["country"];
    this.accSetting.ngOnInit();

  }


  updateUser() {
    let data = {
      customerId: this.user["customerId"],
      customerPhone: this.phone,
      organizationName: this.organization,
      city: this.city,
      state: this.state,
      country: this.country
    }


    this.api.updateUser(data).subscribe(response => {
      if (response["status"]) {
        localStorage.setItem("users", JSON.stringify(response["data"][0]));
        this.showError = response["message"];
        this.assignToInput();
      } else {
        this.showError = response['data'][0];
      }
    }, (err) => {
      console.log(err);
      this.showError = err['error']['data'][0];
    });
  }

}  
