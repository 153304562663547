import { Component, OnInit } from '@angular/core';
import { API } from '../../provider/api'; 
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Params,ActivatedRoute ,Router } from '@angular/router';
import { Location } from '@angular/common'
import { InfoModalsComponent } from '../info-modals/info-modals.component';
import { MatDialog } from '@angular/material/dialog';
//import { create } from 'domain';

@Component({
  selector: 'app-create-new-pw',
  templateUrl: './create-new-pw.component.html',
  styleUrls: ['./create-new-pw.component.css']
})
export class CreateNewPwComponent implements OnInit {
options: FormGroup ;
createpw : FormGroup ;
showError : String ;
name : string ;
emailId : string ;
password : String ;
confPass :string ;
user : [];

  constructor(fb:FormBuilder, public dialog: MatDialog   ,check:FormBuilder, public api : API , public router : Router , public route : ActivatedRoute , public location : Location) {
    this.options = fb.group({
      hideRequired:false,
      floatLabel:'auto',
    });
    this.createpw = check.group({
      'password':[null,Validators.compose([Validators.required,Validators.minLength(6)])],
      'confpass': [null, Validators.compose([Validators.required, Validators.minLength(6)])],
    });

 
    // this.name = "name"
    // this.emailId = "kdubey080@gmail.com"

    
    this.route.queryParams.forEach((params : Params) => {
        this.name = params['name'],
        this.emailId = params['customerId']
    });    
    // console.log(this.name +"  "+this.emailId);
    
    this.location.replaceState('create-new-password');
    //this.location.go('create-new-password');
    //this.router.navigate(['create-new-password']);
   
}
  mes=false ;

  ngOnInit() {
  }

  // assignToInput(){
  //   this.user = JSON.parse(localStorage.getItem('userSubscription'));
  //   this.name = this.user["cusomerId"];
  //   this.emailId = this.user["customerEmailId"];

  //   console.log(name);
  // }
  
  onFormSubmit(valid){
    if(valid){

    //this.user = JSON.parse(localStorage.getItem('userSubscription'));
   
    //this.password = this.createpw.get['password'];
    //this.name=this.user['customerId'];
    this.password=this.createpw.get('password').value;
    this.confPass=this.createpw.get('confpass').value;
    // console.log(this.emailId);
    // console.log(this.password);
    // console.log(this.confPass);
  
    
    this.api.emailPassword(this.emailId,this.password,this.confPass).subscribe(
      response =>{

        if(response["status"]){
          this.showError = response["message"];
          this.mes=true ;
          
          this.dialog.open(InfoModalsComponent, {
            width: "50vw",
            data: {
              title: "Successful Reset Password ",
              content: "Password is reset. You can now signin with new password"
    
            }
          });
          this.createpw.reset();
        }else{
          
          this.showError = response['data'][0];
          console.log(this.showError);

          

        }
      },
      err =>{
        console.log(err);
        this.showError = err['error']['data'][0];
      });
     
    }

  }

}

