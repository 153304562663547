import { Component, OnInit } from '@angular/core';
import { API } from '../../provider/api'
import { CookieService } from 'ngx-cookie-service';
import { AppConstants } from '../../provider/appconstants';
import { MatButtonToggleChange } from '@angular/material';

@Component({
  selector: 'app-new-plan',
  templateUrl: './new-plan.component.html',
  styleUrls: ['./new-plan.component.css']
})
export class NewPlanComponent implements OnInit {

  email : String="";
  plan : String = "";
  clientBaseUrl: String;
  toggle: boolean = true;
  constructor( public api : API,
                private cookie: CookieService,
                public appConstants: AppConstants,
                ) { }

  ngOnInit() {
  this.clientBaseUrl = this.appConstants.baseURL;
  this.getEmail();
  }

  getEmail(){
    //this.email=localStorage.getItem["CustomerId"];
    this.email= this.cookie.get('acEmail');
    // console.log(this.email);
  }

  // selectPlan(plan){
  //   console.log(plan);
  //   //this.api.newSubs(this.email,plan)
  //   this.api.newSubs(this.email,plan).subscribe();

  // }
  
  onFormSubmit(e){
    e.target.submit();
  }


  toggleView(change: MatButtonToggleChange){
    this.toggle = change.value;
  }

}
