import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { SlimLoadingBarModule } from 'ng2-slim-loading-bar';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DemoMaterialModule } from './material.module';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { RatingModule } from 'ng-starrating'; //https://www.npmjs.com/package/ng-starrating
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as exporting from 'highcharts/modules/exporting.src';
import stock from 'highcharts/modules/stock.src';
import more from 'highcharts/highcharts-more.src';
import { HttpClientModule } from '@angular/common/http';
import { Ng5SliderModule } from 'ng5-slider';
import { API } from '../provider/api';

import { HomeComponent } from './home/home.component';
import { DetailGraphComponent } from './detail-graph/detail-graph.component';
import { InfoModalsComponent } from './info-modals/info-modals.component';

import { SigninComponent } from './signin/signin.component'
import { CookieService } from 'ngx-cookie-service';

import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ResetPwComponent } from './reset-pw/reset-pw.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { AccountSettingsComponent } from './account_settings/account-setting.component';
import { AccountInfoComponent } from './account_settings/info/account-info.component';
import { AccountPwdComponent } from './account_settings/pwd/account-pwd.component';
import { AccountBillingComponent } from './account_settings/billing/account-billing.component'
import { AppConstants } from '../provider/appconstants'
import { SignupComponent } from './signup/signup.component';
import { ConfirmComponent } from './dialogbox/confirm.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { CompareValidatorDirective } from './validators/compare-validator.directive';
import { SelectPlanComponent } from './select-plan/select-plan.component';
import { CreateNewPwComponent } from './create-new-pw/create-new-pw.component';
import { PlanBoxComponent } from './plan-box/plan-box.component';
import { CancelPlanModuleComponent } from './cancel-plan-module/cancel-plan-module.component';
import { NewPlanComponent } from './new-plan/new-plan.component';
import { ExpBoxComponent } from './exp-box/exp-box.component';
//rahul
import { DeleteFilterComponent } from './delete-filter/delete-filter.component';
import { FooterComponent } from './footer/footer.component';
import { SignupNewPlanComponent } from './signup-new-plan/signup-new-plan.component';
import { HtmlDecoderPipe } from './pipes/html-decoder.pipe';
import { RedirectGuard } from 'src/provider/RedirectGuard';
export function highchartsModules() {
  // apply Highcharts Modules to this array
  return [stock, more, exporting];
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DetailGraphComponent,
    InfoModalsComponent,
    SigninComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    ResetPwComponent,
    ConfirmComponent,
    AccountPwdComponent,
    AccountInfoComponent,
    AccountBillingComponent,
    AccountSettingsComponent,
    SignupComponent,
    CheckoutComponent,
    CompareValidatorDirective,
    SelectPlanComponent,
    CreateNewPwComponent,
    PlanBoxComponent,
    //rahul
    DeleteFilterComponent,
    CancelPlanModuleComponent,
    NewPlanComponent,
    ExpBoxComponent,
    FooterComponent,
    SignupNewPlanComponent,
    HtmlDecoderPipe
  ],
  imports: [
    BrowserModule,
    SlickCarouselModule,
    RatingModule ,
    HttpClientModule,
    Ng5SliderModule,
    ChartModule,
    NgScrollbarModule,
    SlimLoadingBarModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    FormsModule,
    ReactiveFormsModule
    
  ],
  providers: [
    { provide: HIGHCHARTS_MODULES, useFactory: highchartsModules },
    API,
    RedirectGuard,
    AppConstants ,
    CookieService
    ],
  bootstrap: [AppComponent],
  entryComponents: [
    DetailGraphComponent,
    InfoModalsComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    ConfirmComponent, 
    PlanBoxComponent,
    //rahul
    DeleteFilterComponent,
    CancelPlanModuleComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    ExpBoxComponent,
    FooterComponent,
    SignupNewPlanComponent
  ]
})
export class AppModule { }

platformBrowserDynamic().bootstrapModule(AppModule);
