import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { SigninComponent } from './signin/signin.component'
import { ResetPwComponent } from './reset-pw/reset-pw.component';
import { AccountSettingsComponent } from './account_settings/account-setting.component';
import { AccountInfoComponent } from './account_settings/info/account-info.component';
import { AccountPwdComponent } from './account_settings/pwd/account-pwd.component';
import { AccountBillingComponent } from './account_settings/billing/account-billing.component';
import { SignupComponent } from './signup/signup.component';
import { CheckoutComponent } from './checkout/checkout.component'
import { NewPlanComponent } from './new-plan/new-plan.component'
import { CreateNewPwComponent } from './create-new-pw/create-new-pw.component';
import { SelectPlanComponent } from 'src/app/select-plan/select-plan.component';
import { SignupNewPlanComponent } from './signup-new-plan/signup-new-plan.component';
import { RedirectGuard } from 'src/provider/RedirectGuard';
const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'signin'
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'signin',
    component: SigninComponent
  },
  {
    path: 'reset-password',
    component: ResetPwComponent
  },

  {
    path: 'signup',
    component: SignupComponent
  },
  {
    path: 'select-plan',
    component: SelectPlanComponent
  },
  {
    path: 'new-plan',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: 'http://therumorradar.com/next-steps'
    }
  },
  {
    path: 'reset-password',
    component: ResetPwComponent
  },
  {
    path: 'create-new-password',
    component: CreateNewPwComponent
  },
  {
    path: 'signup-new-plan',
    component: SignupNewPlanComponent
  },

  {
    path: 'account-settings',
    component: AccountSettingsComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'info'
      },
      {
        path: 'info',
        component: AccountInfoComponent
      },
      {
        path: 'pwd',
        component: AccountPwdComponent
      },
      {
        path: 'billing',
        component: AccountBillingComponent
      }
    ]
  },
  {
    path: 'checkout',
    component: CheckoutComponent
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
