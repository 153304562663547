import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(  public dialog: MatDialogRef<PrivacyPolicyComponent> ) { }

  ngOnInit() {
  }

  
  exit(){
    this.dialog.close();
  }
}
