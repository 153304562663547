import { Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SelectPlanComponent } from 'src/app/select-plan/select-plan.component';
import { CancelPlanModuleComponent } from 'src/app/cancel-plan-module/cancel-plan-module.component'
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material';
import { API } from '../../../provider/api' 
import { AccountSettingsComponent } from '../account-setting.component';
import { CookieService } from 'ngx-cookie-service';
@Component({
    selector: 'app-sentiments_billing',
    templateUrl: './account-billing.component.html',
    styleUrls: ['./account-billing.component.css']
  })
  
export class AccountBillingComponent implements OnInit {
  options: FormGroup
  lastFourCard: String;
  cardName: String;
  planType: String;
  nextPayment: String;
  bills: boolean = false;
  billingHistory: [];
  showError:string ;
  customerId:string ;
  cancelSubs: boolean = false;
  // customerSubscriptionLicense : any ;
  constructor(fb: FormBuilder,public dialog: MatDialog,public api :API, public accSetting: AccountSettingsComponent, public cookie: CookieService) {
    this.options = fb.group({
      hideRequired: false,
      floatLabel: 'auto',
    });
    this.updateData();
    // this.upgradeDisable();
    setInterval(() => {this.upgradeDisable(); }, 5000);
  }

  // cancelPlan(){
   
  //   let userSub = JSON.parse(localStorage.getItem('userSubscription'));
  //   let customerId =  userSub.userId;
  //   // console.log(customerId);
  //   this.api.cancelPlan(customerId).subscribe(response => {
  //     // console.log("response", response);
  //   }, err => {
  //     console.log("err",err);
  //   });
  // }

  updateData(){
    let userSubscriptionModel = JSON.parse(localStorage.getItem('userSubscription'));
    this.billingHistory = JSON.parse(localStorage.getItem('billingHistories'));
    let customerSubscriptionLicense = JSON.parse(localStorage.getItem('customerSubscriptionLicense'))
    this.nextPayment = localStorage.getItem('nextPaymentDate');
    this.planType = userSubscriptionModel["subscriptionType"];
    this.lastFourCard = localStorage.getItem('customerCardNumber');
    this.cardName = localStorage.getItem('customerCardName');
    this.cancelSubs = customerSubscriptionLicense["cancelSubs"];
    // this.isCance = customerSubscriptionLicense["cancelSubs"];
    // console.log(this.cancelSubs);
    this.accSetting.ngOnInit();
    
  }

  openSelectPlanModel() {
    const dialogRef = this.dialog.open(SelectPlanComponent, {
      width: '95%',
      height: '100%',
    }); 

    dialogRef.afterClosed().subscribe(result => {
      this.updateData();
      this.upgradeDisable();
    });
    
  }

  cancelPlanBox(){
    const dialogRef = this.dialog.open(CancelPlanModuleComponent,{
      width : '30%' 
    });
    dialogRef.afterClosed().subscribe(result => {
      this.updateData();
    });
    
  }
  
  upgradeDisable(){
    if(this.cancelSubs){
      return false;
    }
    const IsCookieExists: boolean = this.cookie.check('changePlanTimer');
    if (!IsCookieExists) {
      return false ;
    }
      return true ;

  }

ngOnInit() {
  
  }

}  
