import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable()
export class API{
    // apiKey = "https://rhdev.accrete.ai/";
    //apiKey = "https://devlopment.accrete.ai/"
    apiKey = "https://rumorradar.accrete.ai/";
    loginkey= "https://rumorradar.accrete.ai/development/products/login/makeLogin?";
    // apiKeyJava = "http://127.0.0.1:8088";


    constructor(public httpClint: HttpClient) { }



    getRumours(getParam){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Access-Control-Allow-Methods', 'GET, HEAD, POST, PUT, DELETE, OPTIONS, PATCH');

        return this.httpClint.get(this.apiKey+"rh/api/rumors/outperforms?"+getParam, {headers: headers});
    }

    getSnPRumours(){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Access-Control-Allow-Methods', 'GET, HEAD, POST, PUT, DELETE, OPTIONS, PATCH');

        return this.httpClint.get(this.apiKey+"rh/api/rumors/outperforms?sort=-spoutperform", {headers: headers});

    }

    getMostActiveSuperstar(){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Access-Control-Allow-Methods', 'GET, HEAD, POST, PUT, DELETE, OPTIONS, PATCH');

        return this.httpClint.get(this.apiKey+"rh/api/rumors/superstar?lastDays=30", {headers: headers});

    }

    getUniqueRumours(){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Access-Control-Allow-Methods', 'GET, HEAD, POST, PUT, DELETE, OPTIONS, PATCH');

        return this.httpClint.get(this.apiKey+"rh/api/rumors/fresh?sort=-id,-reliability", {headers: headers});
    }

    getMostRumourActivity(){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Access-Control-Allow-Methods', 'GET, HEAD, POST, PUT, DELETE, OPTIONS, PATCH');

        return this.httpClint.get(this.apiKey+"rh/api/rumors/active?lastDays=7", {headers: headers});
    }


    getRumoursDetails(ticker, pageSize, pageNumber){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Access-Control-Allow-Methods', 'GET, HEAD, POST, PUT, DELETE, OPTIONS, PATCH');

        return this.httpClint.get(this.apiKey+"rh/api/rumors?filters=acquiree.ticker:eq:"+ticker+"&lastDays=0&sort=-startTimestamp&size="+pageSize+"&page="+pageNumber, {headers: headers});
    }
    getRumoursDetailsForSource(source, pageSize, pageNumber){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Access-Control-Allow-Methods', 'GET, HEAD, POST, PUT, DELETE, OPTIONS, PATCH');

        return this.httpClint.get(this.apiKey+"rh/api/rumors?filters=source:eq:"+source+"&lastDays=30&size="+pageSize+"&page="+pageNumber, {headers: headers});
    }
    getSectors(){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Access-Control-Allow-Methods', 'GET, HEAD, POST, PUT, DELETE, OPTIONS, PATCH');

        return this.httpClint.get(this.apiKey+"rh/api/sectors", {headers: headers});
    }
    
    getStates(){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Access-Control-Allow-Methods', 'GET, HEAD, POST, PUT, DELETE, OPTIONS, PATCH');

        return this.httpClint.get(this.apiKey+"rh/api/states", {headers: headers});
    }
    
    getStateData(state){
        
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Access-Control-Allow-Methods', 'GET, HEAD, POST, PUT, DELETE, OPTIONS, PATCH');

        return this.httpClint.get(this.apiKey+"rh/api/rumors/outperforms?filters=acquiree.state:eq:"+state, {headers: headers});
    }

    getDealsCount(){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Access-Control-Allow-Methods', 'GET, HEAD, POST, PUT, DELETE, OPTIONS, PATCH');

        return this.httpClint.get(this.apiKey+"rh/api/deals/count", {headers: headers});
    }
    getRumoursCount(){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Access-Control-Allow-Methods', 'GET, HEAD, POST, PUT, DELETE, OPTIONS, PATCH');

        return this.httpClint.get(this.apiKey+"rh/api/rumors/count", {headers: headers});
    }
    getScrapers(){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Access-Control-Allow-Methods', 'GET, HEAD, POST, PUT, DELETE, OPTIONS, PATCH');

        return this.httpClint.get(this.apiKey+"rh/api/rumors/allcount", {headers: headers});
    }
    getScraperrss(){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Access-Control-Allow-Methods', 'GET, HEAD, POST, PUT, DELETE, OPTIONS, PATCH');

        return this.httpClint.get(this.apiKey+"rh/api/scraperrss/count", {headers: headers});
    }
    getFeeds(ticker){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Access-Control-Allow-Methods', 'GET, HEAD, POST, PUT, DELETE, OPTIONS, PATCH');

        return this.httpClint.get(this.apiKey+"rh/api/feeds?filters=ticker:eq:"+ticker+"&sort=-barDate&size=50000", {headers: headers});
    }
    getDealsData(pageSize,pageNumber){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Access-Control-Allow-Methods', 'GET, HEAD, POST, PUT, DELETE, OPTIONS, PATCH');

        return this.httpClint.get(this.apiKey+"rh/api/deals?size="+pageSize+"&page="+pageNumber , {headers: headers});
    }

    getAllFilterData(username){
      let headers = new HttpHeaders();
      headers.append('Access-Control-Allow-Origin', '*');
      headers.append('Access-Control-Allow-Methods', 'GET, HEAD, POST, PUT, DELETE, OPTIONS, PATCH');
      return this.httpClint.get(this.apiKey+"rumorradar/api/filters?filters=custID:eq:"+username, {headers: headers});
    }


    getNotification(username){
      let headers = new HttpHeaders();
      headers.append('Access-Control-Allow-Origin', '*');
      headers.append('Access-Control-Allow-Methods', 'GET, HEAD, POST, PUT, DELETE, OPTIONS, PATCH');

      return this.httpClint.get(this.apiKey+"rumorradar/api/notifications?filters=custID:eq:"+username, {headers: headers});
    }

    updateFilterStatus(filter,filterId){
      let headers = new HttpHeaders();
      headers.append('Access-Control-Allow-Origin', '*');
      headers.append('Access-Control-Allow-Methods', 'GET, HEAD, POST, PUT, DELETE, OPTIONS, PATCH');
      return this.httpClint.put(this.apiKey+"rumorradar/api/filters/"+filterId, filter, {headers: headers});
    }

    deleteFilter(filter,filterId){
      let headers = new HttpHeaders();
      headers.append('Access-Control-Allow-Origin', '*');
      headers.append('Access-Control-Allow-Methods', 'GET, HEAD, POST, PUT, DELETE, OPTIONS, PATCH');

      return this.httpClint.delete(this.apiKey+"rumorradar/api/filters/"+filterId, {headers: headers});
    }

    addFilter(filter){
      let headers = new HttpHeaders();
      headers.append('Access-Control-Allow-Origin', '*');
      headers.append('Access-Control-Allow-Methods', 'GET, HEAD, POST, PUT, DELETE, OPTIONS, PATCH');
      headers.append('Content-Type', 'application/json');

      return this.httpClint.post(this.apiKey+"rumorradar/api/filters", filter, {headers: headers});
    }

    updateNotify(notify,notificationId){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Access-Control-Allow-Methods', 'GET, HEAD, POST, PUT, DELETE, OPTIONS, PATCH');
        headers.append('Content-Type', 'application/json');
        return this.httpClint.put(this.apiKey+"rumorradar/api/notifications/"+notificationId, notify, {headers: headers});
      }
    // forgetPass(userId ,userEmailId){
    //     let headers  = new HttpHeaders();
    //     headers.append('Access-Control-Alow-Origin','*');
    //     headers.append('Access-Control-Allow-Methods','POST,GET,OPTIONS, PUT');
    //     return this.httpClint.post(this.apiKey + '/users/forgetPass?customerId='+userId+'&email='+userEmailId,
    //     null,
    //     {headers:headers });
    // }



    login(credentials) {
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
        headers.append('accept-encoding', 'gzip, deflate');
        // console.log(this.apiKey2 + '/users/login?username=' + encodeURIComponent(credentials.userName) + '&password=' + credentials.password);
        return this.httpClint.post(this.apiKey + '/users/login?username=' + encodeURIComponent(credentials.userName) + '&password=' + encodeURIComponent(credentials.password), null, { headers: headers })
        //return this.httpClint.post("https://rhdev.accrete.ai/users/login?username="+credentials.userName+"&password="+credentials.password, null,{headers: headers});

    }

    getUserData(custId){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
        return this.httpClint.post(this.apiKey + '/users/getuserdetails?userId=' + custId, null, { headers: headers })
    }

    signUp(data) {
        let body = {};
        body["customerPass"] = data.customerPass;
        body["customerName"] = data.customerName;
        body["customerEmailId"] = data.customerEmailId;

        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
        return this.httpClint.post(this.apiKey + '/stripe/signup', body, { headers: headers });
    }


    updateUser(data){
        let body = {};
        body["customerId"] = data.customerId;
        body["customerPhone"] = data.customerPhone;
        body["organizationName"] = data.organizationName;
        body["city"] = data.city;
        body["state"] = data.state;
        body["country"] = data.country;

        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
        return this.httpClint.put(this.apiKey + '/users/update', body, { headers: headers });
    }

    updatePassword(credentials) {
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
        return this.httpClint.post(this.apiKey + '/users/updatePassword?customerId='  + credentials.userName +
        '&oldPassword=' + credentials.oldPassword +
        '&newPassword='+ credentials.newPassword,
         null,
        { headers: headers });
    }

    updatePlan(userid, plancode){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
        return this.httpClint.post(this.apiKey + '/users/changePlan?customerId='  + userid +
        '&planCode=' +plancode,
         null,
        { headers: headers });
    }

    cancelPlan(userId,reason,comment){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
        return this.httpClint.post(this.apiKey + '/users/cancelPlan?customerId='+userId+'&reason='+reason+'&comment='+comment,
         null,
        { headers: headers });
    }

    forgetPass(userId ,userEmailId){
        let headers  = new HttpHeaders();
        headers.append('Access-Control-Alow-Origin','*');
        headers.append('Access-Control-Allow-Methods','POST,GET,OPTIONS, PUT');
        return this.httpClint.post(this.apiKey + '/users/forgetPass?customerId='+userId+'&email='+userEmailId,
        null,
        {headers:headers });
    }

    resetPassCredentials(userId){
        let headers  = new HttpHeaders();
        headers.append('Access-Control-Alow-Origin','*');
        headers.append('Access-Control-Allow-Methods','POST,GET,OPTIONS, PUT');
        return this.httpClint.post(this.apiKey + '/users/resetPassCredentials?customerId='+userId,
        null,
        {headers:headers});
    }

    emailPassword(emailId,newPassword,confirmPassword){
        let headers  = new HttpHeaders();
        headers.append('Access-Control-Alow-Origin','*');
        headers.append('Access-Control-Allow-Methods','POST,GET,OPTIONS, PUT');
        return this.httpClint.post(this.apiKey + '/users/emailPassword?customerId='+emailId+'&newPassword='+newPassword+'&confPassword='+confirmPassword,
        null,
        {headers:headers});
    }

    paymentStatus(emailId){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Alow-Origin','*');
        headers.append('Access-Control-Allow-Methods','POST,GET,OPTIONS,PUT');
        return this.httpClint.post(this.apiKey + '/users/signInPaymentStatus?emailId='+emailId,null,{headers:headers});
    }

    newSubs(emailId,plan){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Alow-Origin','*');
        headers.append('Access-Control-Allow-Methods','POST,GET,OPTIONS,PUT');
        return this.httpClint.post(this.apiKey + '/stripe/newSubscription?planname='+plan+'&email='+emailId,null,{headers:headers});
    }

}
