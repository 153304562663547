import { Component, OnInit } from '@angular/core';
import {MatDialogRef, MatDialog} from '@angular/material';
import {API} from '../../provider/api';
import { Output, EventEmitter } from '@angular/core'; 
import { AccountSettingsComponent } from '../account_settings/account-setting.component';
import { delay } from 'q';

@Component({
  selector: 'app-cancel-plan-module',
  templateUrl: './cancel-plan-module.component.html',
  styleUrls: ['./cancel-plan-module.component.css'],
  providers:[AccountSettingsComponent],
})
export class CancelPlanModuleComponent implements OnInit {

  mandatory: boolean=false;
  disable: boolean=true;
  comment: string="";
  value: string;
  reason: string="";
  plancancel: boolean = false ;
  mes: boolean = false;
  @Output() someEvent = new EventEmitter<string>();

  constructor( public api : API , 
              public diag: MatDialog,
              public dialogRef: MatDialogRef<CancelPlanModuleComponent>,
              ) { }

  ngOnInit() {
  }

  exit(){
    this.diag.closeAll();
    
  }

  cancelPlan(){
    this.mes = true;
    let userSub = JSON.parse(localStorage.getItem('userSubscription'));
    let customerId =  userSub.userId;
    //console.log(customerId);
    this.api.cancelPlan(customerId,this.reason,this.comment).subscribe(response => {
      localStorage.setItem("users", JSON.stringify(response["data"][0]["customerInfoModel"]));
      localStorage.setItem("userSubscription", JSON.stringify(response["data"][0]["userSubscriptionModel"]));
      localStorage.setItem("customerSubscriptionLicense",JSON.stringify(response["data"][0]["customerSubscriptionLicense"]));
      // this.updateData();
      delay(2000);
      this.plancancel=true;
    }, err => {
      console.log(err);
      this.dialogRef.close();
    });
   
  }
  changeReason(){
    //console.log (this.reason);
    //console.log (this.comment);
    
    if( this.reason == ''){
      this.disable=true;
    }else{
    if(this.reason == 'Other'){
        this.mandatory=true
        if(this.comment == "")
        this.disable = true ;
        else{
        this.mandatory=false;
        this.disable = false;
      }
    }
    else{
      this.disable = false ;
      this.mandatory=false;
    }
  }
}
  callParent() {
    this.someEvent.next();
  }
}
